import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserContextProvider, useUserContext } from './context/userContext';
import LoadingPage from './components/Loading/loadingScreen';
import './index.css';
import { SystemContextProvider } from './context/systemContext';
import { TipContextProvider } from './context/tipContext';
import { ROIContextProvider } from './context/roiContext';
import { TierContextProvider } from './context/tierContext';

function AuthIsLoaded({ children }) {
  const { authLoading } = useUserContext();
  if (authLoading) return <LoadingPage />;
  return children;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <AuthIsLoaded>
        <SystemContextProvider>
          <TierContextProvider>
            <TipContextProvider>
              <ROIContextProvider>
                <App />
              </ROIContextProvider>
            </TipContextProvider>
          </TierContextProvider>
        </SystemContextProvider>
      </AuthIsLoaded>
    </UserContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*****************************************************************************/
/*  Date:      Nov 15 2023                                                   */
/*  Modified:                                                                */
/*  Author:    Ben Wakutz                                                    */
/*  Purpose:   Creates a pdf for the ROIv2 Solta US                          */
/*                                                                           */
/*****************************************************************************/

import React from 'react';

import { Page, Text, View, Document, Image, Font, Svg } from '@react-pdf/renderer';
import { BarChart, Bar, XAxis, Legend } from 'recharts';

import { convertToRoundedNumber } from '../../functions/roundNumberUp';
import { convertToCurrency } from '../../functions/convertCurrency';
//import TierLogo from './functions/getTierIcon';
import moment from 'moment';
//import * as MATH from '../../constants/math';
import * as TIMERANGE from '../../constants/timeRanges';

import RobotoBold from '../../fonts/Roboto/Roboto-Bold.ttf';
import RobotoLight from '../../fonts/Roboto/Roboto-Light.ttf';
import RobotoCondensed from '../../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf';
import RobotoCondensedBold from '../../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf';
import RobotoCondensedLight from '../../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf';
import SoltaLogo from '../ROI/images/solta-logo_white.png';
import TierColor from './functions/getTierColor';
import { MakeMoneyValue } from './functions/makeMoneyValue';
import { tierLogo } from './functions/tierLogo';
import { styles } from './styles/pdfStyles';

import ReactPDFChart from 'react-pdf-charts';
//import Chart from './Chart';

// Create Document Component
const ConsumerROIpdf = ({
  data,
  user,
  tips,
  tier,
  growthTips,
  chartData,
  chartXData,
  totalAnnualRevenue,
  totalAnnualTreatmentCosts,
  totalAnnualProfit,
  roiTipCount,
  currentTierBreakEven,
  newxtTierBreakEven,
  selectedTimeRange,
  consumerAnnualTreatmenGrowthtProfit,
  consumerAnnualPracticeGrowthRevenue,
  consumerAnnualTreatmentGrowthCost,
  consumerChartData,
  consumerChartXData,
  timeRangeTreatmentCosts,
  timeRangeProfit,
  timeRangePracticeRevenue,
  selectedTimeBreakdown,
  consumerDifference,
  consumerPracticeGrowthRevenue,
  consumerTreatmentGrowthCost,
  consumerTreatmentGrowthProfit,
  roiGrowthTipCount,
}) => {
  //console.log('Inc Data in pdf creation -> ', data);
  //console.log('Inc user in pdf creation -> ', user);
  //console.log('Inc chartData in pdf creation -> ', chartData);
  //console.log('Inc chartXData in pdf creation -> ', chartXData);
  //console.log('this is my consumer chart data ---->?', consumerChartData, consumerChartXData);
  //console.log('Inc roiTipCount in pdf creation -> ', roiTipCount);
  //console.log('Inc currentTierBreakEven in pdf creation -> ', currentTierBreakEven);
  //console.log('Inc newxtTierBreakEven in pdf creation -> ', newxtTierBreakEven);
  //Font.register({ family: 'FamilyName', src: source, fontStyle: 'normal', fontWeight: 'normal', fonts?: [] });

  Font.register({
    family: 'RobotoCondensed',
    fonts: [
      { src: RobotoCondensed, fontWeight: 'normal' },
      { src: RobotoCondensedBold, fontWeight: 'bold' },
      { src: RobotoCondensedLight, fontWeight: 'light' },
    ],
  });

  Font.register({
    family: 'Roboto',
    fonts: [
      { src: RobotoLight, fontWeight: 'light' },
      { src: RobotoBold, fontWeight: 'normal' },
    ],
  });

  //if(data.mode === "Capital"){
  //console.log('this is my data systems ----> ', data.systems);
  //console.log('this is my tips -----?', tips);

  return (
    <Document>
      {/* PAGE 1 */}
      <Page size='LETTER' style={[styles.page, { backgroundColor: '#fff' }]}>
        <View style={[styles.header, { backgroundColor: '#fff' }]}>
          <View style={[styles.headerTextholder, { color: '#707070' }]}>
            <Text style={[styles.headerText, { color: '#707070' }]}>SOLTA ROI CALCULATOR REPORT</Text>
          </View>
          <View style={styles.logo}>
            {Object.keys(data.systems).map((key) => (
              <Image key={key} src={data.systems[key].logoURL} style={styles.image} />
            ))}
          </View>
        </View>
        <View style={[styles.headerBar, { backgroundColor: '#5A9BAB' }]}>
          <Text>&nbsp;</Text>
        </View>
        {/* Top info section */}
        <View style={styles.clinicInfoBlock}>
          <View style={styles.clinicInfoTopRow}>
            <View style={styles.sectionOne}>
              <Text style={styles.headline}>CLINIC:</Text>
              <Text style={[styles.clinicInfo, { color: '#707070' }]}>{data.clinicName}</Text>
            </View>

            <View style={styles.sectionTwo}>
              <View>
                <Text style={styles.headline}>SYSTEMS:</Text>
              </View>
              <View>
                {Object.keys(data.systems).map((key) => (
                  <View key={key} style={styles.horizontal}>
                    <Image src={data.systems[key].logoURL} style={styles.image} />
                  </View>
                ))}
              </View>
            </View>

            <View style={styles.sectionThree}>
              <Text style={styles.headline}>PURE Tier</Text>
              <View style={styles.horizontal}>
                {tier ? tierLogo(tier) : null} {/* tier logo */}
                <Text style={[styles.tierInfo, { color: '#707070' }]}>{tier ? tierName(tier) : null}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.treatmentsTableSection}>
          <View style={styles.treatTitle}>
            <Text style={styles.uppercase}>Current Treamtents</Text>
          </View>

          {/* tableheaders */}
          <View style={styles.profitTableTopSection}>
            {/* Set up list titles */}
            {/* Treatment Tip */}
            <View style={styles.profitTableTopTreatment}>
              <Text style={styles.uppercase}>
                TREATMENT
                {`\n`} TIP
              </Text>
            </View>

            {/* treatments Per Week  /month */}
            <View style={styles.profitTableTopTreatPerMonth}>
              <Text style={styles.uppercase}>{selectedTimeRange === TIMERANGE.weekly ? 'TREATMENTS\n PER WEEK' : 'TREATMENTS\n PER MONTH'}</Text>
            </View>

            {/* TREATMENT PRICE TO PATIENT */}
            <View style={styles.profitTableTopPatientPrice}>
              <Text style={styles.uppercase}>TREATMENT PRICE{`\n`} TO PATIENT</Text>
            </View>
            {/* CONSUMABLE PRICE */}
            <View style={styles.profitTableTopConsumable}>
              <Text style={styles.uppercase}>CONSUMABLE{`\n`} PRICE</Text>
            </View>
            {/* OTHER CHARGES */}
            <View style={styles.profitTableTopOther}>
              <Text style={styles.uppercase}>OTHER CHARGES</Text>
            </View>
            {/* PROFIT /WEEK */}
            <View style={styles.profitTableTopWeekProfit}>
              <Text style={styles.uppercase}>{selectedTimeRange === TIMERANGE.weekly ? `PROFIT\n /WEEK` : 'PROFIT\n /MONTH'}</Text>
            </View>
          </View>
          <View style={[styles.tableheaderBar, { backgroundColor: '#5A9BAB', opacity: 1 }]}>
            <Text>&nbsp;</Text>
          </View>

          {/* map if data.tips */}

          {tips.length > 0 && tier ? tips.map((tip) => (tip.id !== '' ? <ProfitBlock row={tip} tier={tier} /> : null)) : null}

          <View style={[styles.rowBottom, { backgroundColor: '#5A9BAB', opacity: 1 }]}>
            <Text>&nbsp;</Text>
          </View>
        </View>

        <View style={styles.treatmentsTableSection}>
          <View style={styles.treatTitle}>
            <Text style={styles.uppercase}>Extra Treamtents</Text>
          </View>

          {/* tableheaders */}
          <View style={styles.profitTableTopSection}>
            {/* Set up list titles */}
            {/* Treatment Tip */}
            <View style={styles.profitTableTopTreatment}>
              <Text style={styles.uppercase}>
                TREATMENT
                {`\n`} TIP
              </Text>
            </View>

            {/* treatments Per Week  /month */}
            <View style={styles.profitTableTopTreatPerMonth}>
              <Text style={styles.uppercase}>{selectedTimeRange === TIMERANGE.weekly ? 'TREATMENTS\n PER WEEK' : 'TREATMENTS\n PER MONTH'}</Text>
            </View>

            {/* TREATMENT PRICE TO PATIENT */}
            <View style={styles.profitTableTopPatientPrice}>
              <Text style={styles.uppercase}>TREATMENT PRICE{`\n`} TO PATIENT</Text>
            </View>
            {/* CONSUMABLE PRICE */}
            <View style={styles.profitTableTopConsumable}>
              <Text style={styles.uppercase}>CONSUMABLE{`\n`} PRICE</Text>
            </View>
            {/* OTHER CHARGES */}
            <View style={styles.profitTableTopOther}>
              <Text style={styles.uppercase}>OTHER CHARGES</Text>
            </View>
            {/* PROFIT /WEEK */}
            <View style={styles.profitTableTopWeekProfit}>
              <Text style={styles.uppercase}>{selectedTimeRange === TIMERANGE.weekly ? `PROFIT\n /WEEK` : 'PROFIT\n /MONTH'}</Text>
            </View>
          </View>
          <View style={[styles.tableheaderBar, { backgroundColor: '#5A9BAB', opacity: 1 }]}>
            <Text>&nbsp;</Text>
          </View>

          {/* map if data.tips */}

          {growthTips.length > 0 && tier ? growthTips.map((tip) => (tip.id !== '' ? <ProfitBlock row={tip} tier={tier} /> : null)) : null}

          <View style={[styles.rowBottom, { backgroundColor: '#5A9BAB', opacity: 1 }]}>
            <Text>&nbsp;</Text>
          </View>
        </View>

        {/* teal amounts section */}
        <View style={styles.totalsSection}>
          <View style={[styles.totalsRow, { marginBottom: 28 }]}>
            <View style={styles.totalsOne}>
              <Text style={styles.totalsTitleOne}>CURRENT ANNUAL{'\n'} REVENUE</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(totalAnnualRevenue())}</Text>
            </View>

            <View style={styles.totalsTwo}>
              <Text style={styles.totalsTitleTwo}>CURRENT ANNUAL{'\n'} TREATMENT COSTS</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(totalAnnualTreatmentCosts())}</Text>
            </View>

            <View style={styles.totalsThree}>
              <Text style={styles.totalsTitleThree}>CURRENT TOTAL{'\n'} ANNUAL PROFIT</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(totalAnnualProfit())}</Text>
            </View>
          </View>

          <View style={styles.totalsRow}>
            <View style={styles.totalsOne}>
              <Text style={styles.totalsTitleOneSecond}>POTENTIAL ANNUAL{'\n'} REVENUE</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(consumerAnnualPracticeGrowthRevenue)}</Text>
            </View>

            <View style={styles.totalsTwo}>
              <Text style={styles.totalsTitleTwo}>POTENTIAL ANNUAL{'\n'} TREATMENT COSTS</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(consumerAnnualTreatmentGrowthCost)}</Text>
            </View>

            <View style={styles.totalsThree}>
              <Text style={styles.totalsTitleThree}>POTENTIAL TOTAL{'\n'} ANNUAL PROFIT</Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(consumerAnnualTreatmenGrowthtProfit)}</Text>
            </View>
          </View>
        </View>

        <View style={styles.footerSection}>
          <View style={styles.fullFooter}>
            <Text style={styles.footerText}>
              This is not a contractual document. This document is for information purpose only and does not constitute an offer or create any contractual relationship. We make every reasonable effort to ensure the accuracy and validity of the information provided. However, as policies, price points, dates, conditions, and information are continually changing, we reserve the
              right to make changes at any time and accept no liability or responsibility for any errors or omissions.
            </Text>
          </View>
          <View style={styles.fullFooter}>
            <View style={styles.footLeft}>
              <Text style={styles.footerText}>Solta Medical is a global leader in the aesthetic industry providing innovative products such as Liposonix®, VASERlipo®, VASER® ultrasonic systems, Thermage® radio frequency and Isolaz® acne therapy systems, and the Clear + Brilliant®, Clear + Brilliant Touch® and Fraxel® lasers.</Text>
            </View>
            <View style={styles.footRight}>
              <Text style={styles.footerText}>®/TM are trademarks of Bausch Health Companies Inc. or its affiliates. ©{moment().format('YYYY')} Bausch Health Companies Inc. SAL.XXX.USD23</Text>
            </View>
          </View>
        </View>
      </Page>

      {/* PAGE 2 */}
      <Page size='LETTER' style={[styles.page, { backgroundColor: '#fff' }]}>
        <View style={[styles.header, { backgroundColor: '#fff' }]}>
          <View style={[styles.headerTextholder, { color: '#707070' }]}>
            <Text style={[styles.headerText, { color: '#707070' }]}>SOLTA ROI CALCULATOR REPORT</Text>
          </View>
          <View style={styles.logo}>
            {Object.keys(data.systems).map((key) => (
              <Image key={key} src={data.systems[key].logoURL} style={styles.image} />
            ))}
          </View>
        </View>
        <View style={[styles.headerBar, { backgroundColor: '#5A9BAB' }]}>
          <Text>&nbsp;</Text>
        </View>

        {/* Graph section */}
        <View style={styles.page2Graph}>
          {/* title */}
          <View style={styles.page2header}>
            <Text style={styles.page2subtitle}>
              CUMULATIVE PROFIT BASED ON {roiGrowthTipCount} TREATMENT PER {selectedTimeRange === TIMERANGE.weekly ? 'WEEK' : 'MONTH'}
            </Text>
          </View>
          <View style={styles.page2subBar}>
            <View style={styles.horizontal}>
              {/* weekly/monthly with bar under */}
              <View style={styles.verticle}>
                <Text style={styles.weekly}>{selectedTimeBreakdown}</Text>
              </View>
              {/* TierLOGO    Tier Name */}
              <View style={styles.page2subTier}>
                <View style={styles.p2LogoHolder}>
                  {tierLogo(tier)} {/* tier logo */}
                </View>
                <Text style={[styles.tierInfo, { color: '#707070' }]}>{tierName(tier)}</Text>
              </View>
            </View>
          </View>
          {/* graph section */}
          <View style={styles.graphBlock}>
            <View style={styles.breakWeeksHolder}>
              <View style={styles.tierBreak}>
                <Text style={styles.currentTierWeeks}>${convertToCurrency(consumerDifference.growthDifference)}</Text>
                <Text style={[styles.breaktextDifference, styles.uppercase]}>
                  POTENTIAL {selectedTimeBreakdown}
                  {'\n'} PROFIT DIFFERENCE{' '}
                </Text>
              </View>
            </View>

            {/* FIRST GRAPH */}
            <View tyle={styles.graphHolder}>
              <CreateBarChart chartData={chartData} chartXData={chartXData} />
            </View>
          </View>
        </View>

        {/* teal amounts section */}
        <View style={styles.p2TotalsSection}>
          <View style={[styles.totalsRow, { marginBottom: 28 }]}>
            <View style={styles.p2totalsOne}>
              <Text style={styles.totalsTitleOne}>
                {' '}
                CURRENT {selectedTimeBreakdown}
                {'\n'} PRACTICE REVENUE
              </Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(timeRangePracticeRevenue())}</Text>
            </View>

            <View style={styles.totalsTwo}>
              <Text style={styles.totalsTitleTwo}>
                CURRENT {selectedTimeBreakdown}
                {'\n'} TREATMENT COSTS
              </Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(timeRangeTreatmentCosts())}</Text>
            </View>

            <View style={styles.totalsThree}>
              <Text style={styles.totalsTitleThree}>
                CURRENT {selectedTimeBreakdown}
                {'\n'} PROFIT
              </Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(timeRangeProfit())}</Text>
            </View>
          </View>
          <View style={[styles.totalsRow]}>
            <View style={styles.p2totalsOne}>
              <Text style={styles.totalsTitleOne}>
                POTENTIAL {selectedTimeBreakdown}
                {'\n'} PRACTICE REVENUE
              </Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(consumerPracticeGrowthRevenue)}</Text>
            </View>

            <View style={styles.totalsTwo}>
              <Text style={styles.totalsTitleTwo}>
                POTENTIAL {selectedTimeBreakdown}
                {'\n'} TREATMENT COSTS
              </Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(consumerTreatmentGrowthCost)}</Text>
            </View>

            <View style={styles.totalsThree}>
              <Text style={styles.totalsTitleThree}>
                POTENTIAL {selectedTimeBreakdown}
                {'\n'} PROFIT
              </Text>
              <Text style={styles.totalsTotals}>${convertToCurrency(consumerTreatmentGrowthProfit)}</Text>
            </View>
          </View>
        </View>

        {/* FOOTER */}
        <View style={styles.footerSection}>
          <View style={styles.fullFooter}>
            <Text style={styles.footerText}>
              This is not a contractual document. This document is for information purpose only and does not constitute an offer or create any contractual relationship. We make every reasonable effort to ensure the accuracy and validity of the information provided. However, as policies, price points, dates, conditions, and information are continually changing, we reserve the
              right to make changes at any time and accept no liability or responsibility for any errors or omissions.
            </Text>
          </View>
          <View style={styles.fullFooter}>
            <View style={styles.footLeft}>
              <Text style={styles.footerText}>Solta Medical is a global leader in the aesthetic industry providing innovative products such as Liposonix®, VASERlipo®, VASER® ultrasonic systems, Thermage® radio frequency and Isolaz® acne therapy systems, and the Clear + Brilliant®, Clear + Brilliant Touch® and Fraxel® lasers.</Text>
            </View>
            <View style={styles.footRight}>
              <Text style={styles.footerText}>®/TM are trademarks of Bausch Health Companies Inc. or its affiliates. ©{moment().format('YYYY')} Bausch Health Companies Inc. SAL.XXX.USD23</Text>
            </View>
          </View>
        </View>
      </Page>

      {/* PAGE 3 */}
      <Page size='LETTER' style={[styles.page, { backgroundColor: '#fff' }]}>
        <View style={[styles.header, { backgroundColor: '#fff' }]}>
          <View style={[styles.headerTextholder, { color: '#707070' }]}>
            <Text style={[styles.headerText, { color: '#707070' }]}>SOLTA ROI CALCULATOR REPORT</Text>
          </View>
          <View style={styles.logo}>
            {Object.keys(data.systems).map((key) => (
              <Image key={key} src={data.systems[key].logoURL} style={styles.image} />
            ))}
          </View>
        </View>
        <View style={[styles.headerBar, { backgroundColor: '#5A9BAB' }]}>
          <Text>&nbsp;</Text>
        </View>

        {/* Graph section */}
        <View style={styles.page2Graph}>
          {/* title */}

          <View style={styles.page2subBar}>
            <View style={styles.horizontal}>
              {/* weekly/monthly with bar under */}
              <Text style={styles.page2subtitle}>
                CUMULATIVE PURE TIER PROGRESS BASED {roiGrowthTipCount} TREATMENT PER {selectedTimeRange === TIMERANGE.weekly ? 'WEEK' : 'MONTH'}
              </Text>
              {/* TierLOGO    Tier Name */}
              <View style={styles.page2subTier}>
                <View style={styles.p2LogoHolder}>
                  {tierLogo(tier)} {/* tier logo */}
                </View>
                <Text style={[styles.tierInfo, { color: '#707070' }]}>{tierName(tier)}</Text>
              </View>
            </View>
          </View>
          {/* graph section */}
          <View style={[styles.graphBlock, styles.secondGraph]}>
            {/* SECOND GRAPH */}
            <View style={[styles.graphHolder]}>
              <CreateBarChart chartData={consumerChartData} chartXData={consumerChartXData} />
            </View>
          </View>
        </View>

        {/* teal sales rep section */}
        <View style={styles.totalsSection}>
          <View style={styles.totalsRow}>
            <View style={styles.p3SalesRep}>
              <Text style={styles.repheader}>SALES REP:</Text>
              <Text style={styles.repName}>{user.fullName}</Text>
              <Text style={styles.repEmail}>{user.email}</Text>
              <Text style={styles.repPhone}>{user.phone}</Text>
            </View>
            <View style={styles.p3companySection}>
              <View>
                <Image style={styles.p3SoltaLogo} src={SoltaLogo} />
              </View>
              <Text style={styles.p3CompanyAddress}>11720 North Creek</Text>
              <Text style={styles.p3CompanyAddress}>Parkway North Suite 100 | </Text>
              <Text style={styles.p3CompanyAddress}>Bothell, WA 98011</Text>
            </View>
          </View>
        </View>

        {/* FOOTER */}
        <View style={styles.footerSection}>
          <View style={styles.fullFooter}>
            <Text style={styles.footerText}>
              This is not a contractual document. This document is for information purpose only and does not constitute an offer or create any contractual relationship. We make every reasonable effort to ensure the accuracy and validity of the information provided. However, as policies, price points, dates, conditions, and information are continually changing, we reserve the
              right to make changes at any time and accept no liability or responsibility for any errors or omissions.
            </Text>
          </View>
          <View style={styles.fullFooter}>
            <View style={styles.footLeft}>
              <Text style={styles.footerText}>Solta Medical is a global leader in the aesthetic industry providing innovative products such as Liposonix®, VASERlipo®, VASER® ultrasonic systems, Thermage® radio frequency and Isolaz® acne therapy systems, and the Clear + Brilliant®, Clear + Brilliant Touch® and Fraxel® lasers.</Text>
            </View>
            <View style={styles.footRight}>
              <Text style={styles.footerText}>®/TM are trademarks of Bausch Health Companies Inc. or its affiliates. ©{moment().format('YYYY')} Bausch Health Companies Inc. SAL.XXX.USD23</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
  /* consumer */
  /* }else{
      
      return(
        <Document></Document>
      )

    } */
};

const tierName = (id) => {
  //we have id, we need name

  switch (id) {
    case '"EnHRIMZGhhJZeTCUL6NX"':
      return 'Bronze';
    case '3IaLvKVToLsbcC8neru7':
      return 'Silver';
    case 'hfgJtLRuei3Ac42Gu1tj':
      return 'Gold';
    case 'TktE8KkUfRptydPNLOk1':
      return 'Platinum';
    case 'eEH4ihpXS9hqi1Bd3Q93':
      return 'Emerald';
    case '3vYBprZ763vNRowuvVRI':
      return 'Ruby';
    case '7FiOjjal0x2LkVmv9gbv':
      return 'Diamond';
    default:
      return 'Bronze';
  }
};

const ProfitBlock = ({ row, i, tier }) => {
  //console.log('In profit block, passed in row->', row);
  //console.log('this is my tier 0000 ', tier);

  const otherCost = row.other ?? 0;
  //console.log('this is my other ofc ost ---', otherCost);
  //console.log('these are my values --->', row.treatmentPrice, row[tier], row.tipsPerPack, otherCost, row.amount);
  //console.log('this i s my amount ----?> ', (row.treatmentPrice - row[tier] / row.tipsPerPack + otherCost) * row.amount);
  // each rowdata map sent in here for formatting and return of a view -> line in the "table"
  return (
    <View key={i} style={styles.profitRow}>
      <View style={styles.profitRowTreatment}>
        {/* Treatment Tip */}
        <Text style={styles.itemDescription}>{row.sku}</Text>
      </View>
      {/* treatments Per Week  /month */}
      <View style={styles.profitRowTreatPerMonth}>
        <Text style={styles.itemDescription}>{row.amount}</Text>
      </View>
      {/* TREATMENT PRICE TO PATIENT */}
      <View style={styles.profitRowPatientPrice}>
        <Text style={styles.itemDescription}>
          {'$'}
          {MakeMoneyValue({ value: row.treatmentPrice })}
        </Text>
      </View>
      {/* CONSUMABLE PRICE */}
      <View style={styles.profitRowConsumable}>
        <Text style={styles.itemDescription}>
          {'$'}
          {MakeMoneyValue({ value: row[tier] / row.tipsPerPack })}
        </Text>
      </View>
      {/* OTHER CHARGES */}
      <View style={styles.profitRowOther}>
        <Text style={styles.itemDescription}>
          {'$'}
          {MakeMoneyValue({ value: otherCost })}
        </Text>
      </View>
      {/* PROFIT /WEEK */}
      <View style={styles.profitRowWeekProfit}>
        <Text style={styles.itemDescription}>${MakeMoneyValue({ value: (row.treatmentPrice - row[tier] / row.tipsPerPack + otherCost) * row.amount })}</Text>
      </View>
    </View>
  );
};

const CreateBarChart = ({ chartData, chartXData }) => {
  return (
    <ReactPDFChart>
      <BarChart
        width={575}
        height={225}
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          bottom: 5,
        }}
      >
        <XAxis dataKey='name' />
        <Legend />
        {chartXData.map((xAxis) => (
          <Bar isAnimationActive={false} dataKey={xAxis.key} fill={`${TierColor(xAxis.key)}`} key={xAxis.key} />
        ))}
      </BarChart>
    </ReactPDFChart>
  );
};

export default ConsumerROIpdf;

import React, { createContext, useState, useContext, useEffect } from 'react';
import { firestore } from '../firebase/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, addDoc, query, where, onSnapshot, orderBy, getDoc, doc, setDoc, serverTimestamp, deleteDoc, updateDoc } from 'firebase/firestore';
import { useSystemContext } from './systemContext';
import { useUserContext } from './userContext';
import { formatDate } from '../functions/convertDate';
import { convertToNumber } from '../functions/convertNumber';
import { convertToRoundedNumber } from '../functions/roundNumberUp';
import { convertToCurrency } from '../functions/convertCurrency';
import * as TIMERANGE from '../constants/timeRanges';
import * as TIMERANGESINGULAR from '../constants/timeRangeSingular';
import * as MATH from '../constants/math';
import * as MODE from '../constants/modes';
import { useTierContext } from './tierContext';
import ROIReport from '../components/ROI/roiPDF';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ConsumerROIpdf from '../components/ROI/consumerPDF';
//import TierColor from '../../../roi-calculator/src/components/ROI/functions/getTierColor';
//import * as ROUTES from '../constants/routes';

const ROIContext = createContext({});

export const ROIContextProvider = ({ children }) => {
  const [roiLoading, setROILoading] = useState(true);
  const [repROIsLoading, setRepsROIsLoading] = useState(true);
  const [roiAlert, setRoiAlert] = useState(false);
  const [roiError, setROIError] = useState('');
  const [repROIs, setRepROIs] = useState([]);
  const [roiSaving, setROISaving] = useState(false);
  const [forceROISave, setForceROISave] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [tipDrawerOpen, setTipDrawerOpen] = useState(true);

  const { systems, systemAddedToROI, setSystemsSelected } = useSystemContext();
  const { user, setRefreshUser } = useUserContext();
  const { tiers, tiersLoading } = useTierContext();

  const defaultTips = [{ id: '' }, { id: '' }, { id: '' }];
  const defaultGrowthTips = [
    { id: '', type: 'growth' },
    { id: '', type: 'growth' },
    { id: '', type: 'growth' },
  ];

  //ROI SPECIFIC VARAIBLES
  const [roi, setROI] = useState({});
  const [PDFroi, setPDFROI] = useState({});
  const [pdfCapitalDataReady, setPDfCapitalDataReady] = useState(false);
  const [pdfConsumerDataReady, setPDfConsumerDataReady] = useState(false);
  const [roiID, setROIID] = useState(null);
  const [clinicTaxBraket, setClinicTaxBraket] = useState(0);
  const [addFinancing, setAddFinancing] = useState(false);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [interstRate, setinterestRate] = useState(0);
  const [selectedTier, setSelectedTier] = useState('');
  const [selectedTips, setSelectedTips] = useState(defaultTips);
  const [growthTips, setGrowthTips] = useState(defaultGrowthTips);
  const [selectedTimeRange, setSelectedTimeRange] = useState(TIMERANGE.weekly);
  const [selectedTimeBreakdown, setSelectedBreakdown] = useState(TIMERANGE.weekly);
  const [nextTier, setNextTier] = useState({});
  const [systemCosts, setSystemCosts] = useState(0);
  const [tableBreakdown, setTableBreakdown] = useState([]);
  const [roiTipCount, setROITipCount] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [chartXData, setChartXData] = useState([]);
  const [currentTierBreakEven, setCurrentTierBreakEven] = useState({});
  const [newxtTierBreakEven, setNextTierBreakEven] = useState({});
  const [roiMode, setROIMode] = useState(MODE.capital);

  //Consumer Variables
  const [currentSpend, setCurrentSpend] = useState(0);
  const [consumerChartData, setConsumerChartData] = useState([]);
  const [consumerChartXData, setConsumerChartXData] = useState([]);
  const [roiGrowthTipCount, setROIGrowthTipCount] = useState(0);
  const [consumerDifference, setConsumerDifference] = useState({});
  const [consumerPracticeGrowthRevenue, setconsumerPracticeGrowthRevenue] = useState(0);
  const [consumerTreatmentGrowthCost, setconsumerTreatmentGrowthCost] = useState(0);
  const [consumerTreatmentGrowthProfit, setconsumerTreatmentGrowthProfit] = useState(0);
  const [consumerAnnualPracticeGrowthRevenue, setconsumerAnnualPracticeGrowthRevenue] = useState(0);
  const [consumerAnnualTreatmentGrowthCost, setconsumerAnnualTreatmentGrowthCost] = useState(0);
  const [consumerAnnualTreatmenGrowthtProfit, setconsumerAnnualTreatmenGrowthtProfit] = useState(0);

  //Consumer TotalCosts
  const [consumerPracticeRevenue, setconsumerPracticeRevenue] = useState(0);
  const [consumerTreatmentCost, setConsumerTreatmentCost] = useState(0);
  const [consumerTreatmentProfit, setConsumerTreatmentProfit] = useState(0);
  const [consumerAnnualPracticeRevenue, setConsumerAnnualPracticeRevenue] = useState(0);
  const [consumerAnnualTreatmentCost, setConsumerAnnualTreatmentCost] = useState(0);
  const [consumerAnnualTreatmentProfit, setConsumerAnnualTreatmentProfit] = useState(0);

  //PDF Variables
  const [pdfLoading, setPDFLoading] = useState(false);

  //console.log('this is my user ---->', user);

  //console.log('!!!!these are my selected Tips ----> ', selectedTips);
  //console.log('thiese are my seleted Time ranges ---->< ', selectedTimeRange);

  /////////////////////////////////////////////
  //
  // Get Reps ROIs via Snapshot
  // >> Get all of the reps rois based on their id
  // >>> Sorted by the newest at the top
  //
  /////////////////////////////////////////////

  useEffect(() => {
    const repROISnapShot = onSnapshot(query(collection(firestore, 'roi'), where('rep', '==', user ? user.id : ''), orderBy('added', 'desc')), (snapshot) => {
      let repsROI = [];

      snapshot.forEach((doc) => {
        repsROI.push({ id: doc.id, started: formatDate(doc.data().added), ...doc.data() });
      });

      setRepROIs([...repsROI]);
      setRepsROIsLoading(false);
    });

    return () => {
      repROISnapShot();
      setRepsROIsLoading(false);
    };
  }, [repROIsLoading, user]);

  /////////////////////////////////////////////
  //
  // Autosave
  // >> Save the roi that the rep is working on
  // >>> Will save every minute
  //
  /////////////////////////////////////////////

  useEffect(() => {
    /////////////////////////////////////////////
    //
    // Set ROI to Save
    // >> This function will standerize our ROI data for Firestroe
    //
    /////////////////////////////////////////////
    const setROIForFirestore = () => {
      // We want to strip the following fields
      // ID
      // Added
      //console.log('we are going to be saving our ROI ----- TIME RANGE ', selectedTimeRange);

      return {
        rep: roi.rep,
        clinicName: roi.clinicName ?? '',
        reportName: roi.reportName ?? '',
        systems: {
          ...(roi.systems ?? null),
        },
        taxBraket: clinicTaxBraket,
        financing: addFinancing,
        interstRate: interstRate,
        monthlyPayment: monthlyPayment,
        tier: selectedTier,
        tips: [...selectedTips],
        selectedTimeRange: selectedTimeRange,
        mode: roiMode,
        currentSpend: currentSpend,
        growthTips: [...growthTips],
        folder: roi.folder ?? '',
      };
    };

    /////////////////////////////////////////////
    //
    // Save ROI
    // >> This function will save the a reps ROI
    //
    /////////////////////////////////////////////
    const saveROI = async () => {
      try {
        setROISaving(true);
        //console.log('we are saving my roi');
        const cleanROI = setROIForFirestore();
        //console.log('this is my clean roi ----> ', cleanROI);
        const pdfdata = await generateROIpdfDataSet(roi);
        //console.log("pdfdata in save after generate",pdfdata);
        await setDoc(
          doc(firestore, 'roi', roiID),
          {
            ...cleanROI,
            saved: serverTimestamp(),
            pdfData: pdfdata,
          },
          { merge: true }
        );
        setROISaving(false);
        setForceROISave(false);
        return true;
      } catch (err) {
        console.log('this is my error ---->', err);
        return err;
      }
    };

    if (forceROISave) {
      //console.log('lets force a save ---> ', forceROISave);
      saveROI();
    }

    const interval = setInterval(() => {
      //Check to see if the roi is done loading
      if (!roiLoading) {
        //console.log('is my roi loaded? ---> ', roiLoading);
        //If we have an ROI ID then we should be able to save
        if (roiID) {
          saveROI();
        }
      }
      return () => {
        clearInterval(interval);
      };
    }, 6000 * 1000 /* 60 every minute - 60000 for testing */);
  }, [roiLoading, roi, roiID, systems, clinicTaxBraket, addFinancing, monthlyPayment, interstRate, selectedTier, selectedTips, selectedTimeRange, forceROISave, roiMode, currentSpend, growthTips]);

  /////////////////////////////////////////////
  //
  // Get the next tier
  // >> Will get fired when the selected tier changes
  // >>> Find the next tier on the list
  //
  /////////////////////////////////////////////

  useEffect(() => {
    if (selectedTier && !tiersLoading) {
      const foundTier = tiers.findIndex((tier) => tier.id === selectedTier);
      if (foundTier !== -1 && foundTier !== tiers.length - 1) {
        const nextTier = tiers[foundTier + 1];
        return setNextTier({ ...nextTier });
      } else {
        return setNextTier({});
      }
    }
    return () => {};
  }, [selectedTier, tiers, tiersLoading]);

  /////////////////////////////////////////////
  //
  // Get set the total system cost
  // >> Will get fired when the systems get added
  // >>> Add the total cost of each system
  //
  /////////////////////////////////////////////

  useEffect(() => {
    if (roi) {
      if (roi.systems) {
        const systemCosts = Object.entries(roi.systems).map(([key, value]) => value.price);

        const totalSystemCost = systemCosts.reduce((acc, curr) => acc + curr, 0);

        return setSystemCosts(totalSystemCost);
      }
    }

    return () => {};
  }, [roi]);

  /////////////////////////////////////////////
  //
  // Calculate the Table of Tips
  // >> Based on the amount of tips calculated
  // >> we will then make a chart of all of the data
  // - Treamtents / {Selected Time Frame}
  // - {Selected Time Frame } Tip Cost (Average)
  // - Loan Mayment
  // - {Selected Time Frame } Sales
  // - {Selected Time Frame } Profit
  // - {Selected Time Frame } to Break Even
  //
  /////////////////////////////////////////////

  useEffect(() => {
    if (selectedTier && !tiersLoading) {
      const foundTier = tiers.find((tier) => tier.id === selectedTier);

      const tableLength = 10;

      let tableData = [];
      if (roiTipCount > 0) {
        //If tip count is less then 6 we will then only add up to 12

        //We will need to get the average tip cost
        const tipCosts = selectedTips.map((tip) => (tip[foundTier.id] ? (tip[foundTier.id] / tip.tipsPerPack) * tip.amount : 0));
        const tipTotal = tipCosts.reduce((sum, price) => sum + price, 0);
        const tipAverage = tipTotal / roiTipCount;

        //Calculate the other costs
        const otherCosts = selectedTips.map((tip) => (tip.other ? (tip.other ?? 0) * tip.amount : 0));

        const otherTotal = otherCosts.reduce((sum, price) => sum + price, 0);
        const otherAverage = otherTotal / roiTipCount;

        //We will then need to figure out the average sales for all of the treatments
        const treatmentSales = selectedTips.map((tip) => (tip.treatmentPrice ? tip.treatmentPrice * tip.amount : 0));
        const treatmentTotal = treatmentSales.reduce((sum, price) => sum + price, 0);
        const treatmentAverage = treatmentTotal / roiTipCount;

        if (roiTipCount < 5) {
          for (let i = 1; i <= 1 + tableLength - 1; i++) {
            tableData.push({ treatment: i });
          }
        } else {
          for (let i = roiTipCount - 5; i <= roiTipCount - 5 + (roiTipCount + 1); i++) {
            tableData.push({ treatment: i });
          }
        }

        //Now that our starting table data has been built we can calcuate out the rest
        let calculatedTableData = [];
        tableData.map((row, i) => {
          const tipCount = row.treatment;
          const tipCost = tipAverage * tipCount;
          const loanPayment = selectedTimeRange === TIMERANGE.weekly ? monthlyPayment / 4 : monthlyPayment;
          const sales = treatmentAverage * tipCount;
          const otherCost = otherAverage * tipCount;
          const profit = sales - tipCost - loanPayment - otherCost;

          //Figure out how much the machines cost after tax duductions
          const taxBraket = convertToNumber(clinicTaxBraket) / 100; //Comes in as a whole number / 100 to make a decimal
          const taxSaving = systemCosts * taxBraket;
          const taxDeduction = systemCosts - taxSaving;

          const breakEven = taxDeduction / profit;

          return calculatedTableData.push({ treatments: tipCount, tipcost: tipCost, otherCosts: otherCost, loanPayment: loanPayment, sales: sales, profit: profit, breakEven: breakEven });
        });

        setTableBreakdown([...calculatedTableData]);
      }
    }
    return () => {};
  }, [selectedTier, tiers, selectedTimeRange, selectedTips, roiTipCount, tiersLoading, systemCosts, clinicTaxBraket, monthlyPayment]);

  /////////////////////////////////////////////
  //
  // Tip Count
  // >> This function will calculate how many tips are in
  //    used
  //
  /////////////////////////////////////////////

  useEffect(() => {
    let tipCount = 0;

    const mySelectedTips = selectedTips.map((tip) => (tip.amount ? convertToNumber(tip.amount) : 0));
    //console.log('this is my selected tips ----< ', mySelectedTips);
    tipCount = mySelectedTips.reduce((acc, curr) => acc + curr, 0);
    //console.log('this is my tip Count ---> ', tipCount);
    setROITipCount(tipCount);
    return () => {};
  }, [selectedTips]);

  /////////////////////////////////////////////
  //
  // Growth Tip Count
  // >> This function will calculate how many tips are in
  //    used
  //
  /////////////////////////////////////////////

  useEffect(() => {
    let tipCount = 0;
    let growthTipCount = 0;
    const mySelectedTips = selectedTips.map((tip) => (tip.amount ? convertToNumber(tip.amount) : 0));
    const mySelectedGrowthTips = growthTips.map((tip) => (tip.amount ? convertToNumber(tip.amount) : 0));
    //console.log('this is my selected tips ----< ', mySelectedTips);
    tipCount = mySelectedTips.reduce((acc, curr) => acc + curr, 0);
    growthTipCount = mySelectedGrowthTips.reduce((acc, curr) => acc + curr, 0);
    //console.log('this is my tip Count ---> ', tipCount);
    setROIGrowthTipCount(tipCount + growthTipCount);
    return () => {};
  }, [selectedTips, growthTips]);

  /////////////////////////////////////////////
  //
  // Calculate the CAPITAL Chart Data Profits
  // >> Based on the amount of tips calculated
  // -  We will also need to know how we are calculating out tips WEEKLY OR MONTHLY
  //  - Need to figure out current tier
  //  - Need to figure out next tier up if not diamond
  //  - We will then need to breakout the information based on the next SELECTED TIME RANGE BREAKDOWN
  //  - Need to adjust based on the viewing information
  //
  /////////////////////////////////////////////

  useEffect(() => {
    //console.log('values for selectedTier && tiersLoading && roiMode ', selectedTier, tiersLoading, roiMode);
    if (selectedTier && !tiersLoading && roiMode === MODE.capital) {
      //console.log('The condition is true');
    } else {
      //console.log('The condition is false');
    }
    if (selectedTier && !tiersLoading && roiMode === MODE.capital) {
      const foundTier = tiers.findIndex((tier) => tier.id === selectedTier);
      const myTier = tiers.find((tier) => tier.id === selectedTier);

      if (roiTipCount > 0) {
        //If tip count is less then 6 we will then only add up to 12
        //////////////////////////////////////////////////////
        //
        // Current Tier Information
        //
        //////////////////////////////////////////////////////
        //We will need to get the average tip cost
        const tipCosts = selectedTips.map((tip) => (tip[selectedTier] ? (tip[selectedTier] / tip.tipsPerPack) * tip.amount : 0));
        const tipTotal = tipCosts.reduce((sum, price) => sum + price, 0);
        const tipAverage = tipTotal / roiTipCount;

        //Calculate the other costs
        const otherCosts = selectedTips.map((tip) => (tip.other ? (tip.other ?? 0) * tip.amount : 0));

        const otherTotal = otherCosts.reduce((sum, price) => sum + price, 0);
        const otherAverage = otherTotal / roiTipCount;

        //We will then need to figure out the average sales for all of the treatments
        const treatmentSales = selectedTips.map((tip) => (tip.treatmentPrice ? tip.treatmentPrice * tip.amount : 0));
        const treatmentTotal = treatmentSales.reduce((sum, price) => sum + price, 0);
        const treatmentAverage = treatmentTotal / roiTipCount;

        const tipCost = tipAverage * roiTipCount;
        const loanPayment = selectedTimeRange === TIMERANGE.weekly ? monthlyPayment / 4 : monthlyPayment;
        const sales = treatmentAverage * roiTipCount;
        const otherCost = otherAverage * roiTipCount;
        const profit = sales - tipCost - loanPayment - otherCost;

        //Figure out how much the machines cost after tax duductions
        const taxBraket = convertToNumber(clinicTaxBraket) / 100; //Comes in as a whole number / 100 to make a decimal
        const taxSaving = systemCosts * taxBraket;
        const taxDeduction = systemCosts - taxSaving;

        ///////////////////////////////////////////////////////////////////////////////////////////
        //
        //Now that we have the calculations we will next need to figure out how they are being calculated
        // Selected Time Range - Weekly or Monthly
        // We will need to make adjustments accorenlty
        //
        ///////////////////////////////////////////////////////////////////////////////////////////

        let xAxis = [{ key: myTier.tierName }];

        let chartData = [];
        let tierBreakEven = {};

        const monthBreakdown = 4;
        const quarterBreakdown = 8;
        const yearBreakdown = 12;

        if (selectedTimeRange === TIMERANGE.weekly) {
          //Tips are being calculated by weeks

          switch (selectedTimeBreakdown) {
            case TIMERANGE.weekly:
              //Tips are being calculated by weeks
              //profits * 1  mapped 48 times for the values
              //Chart will show 48 weeks
              for (let i = 0; i < MATH.weeklyAnnual; i++) {
                chartData.push({ [myTier.tierName]: profit * (i + 1), name: `Week ${i + 1}` });
              }

              // Breakdown by weeks
              tierBreakEven = { breakEven: taxDeduction / profit, breakdown: 'Weeks' };

              break;
            case TIMERANGE.monthly:
              //Tips are being calculated by weeks * 4
              //Chart will show 12 Months * 4
              for (let i = 0; i < MATH.monthlyAnnual * monthBreakdown; i++) {
                chartData.push({ [myTier.tierName]: profit * MATH.weeklyMonthly * (i + 1), name: `Month ${i + 1}` });
              }
              // Breakdown by Months
              tierBreakEven = { breakEven: taxDeduction / (profit * MATH.weeklyMonthly), breakdown: 'Months' };
              break;
            case TIMERANGE.quarterly:
              //Tips are being calculated by weeks * 12
              //Chart will show 4 quarters * 12
              for (let i = 0; i < MATH.quarterlyAnnual * quarterBreakdown; i++) {
                chartData.push({ [myTier.tierName]: profit * MATH.weeklyQuarterly * (i + 1), name: `Quarter ${i + 1}` });
              }
              // Breakdown by quarters
              tierBreakEven = { breakEven: taxDeduction / (profit * MATH.weeklyQuarterly), breakdown: 'Quarters' };
              break;
            case TIMERANGE.annual:
              //Tips are being calculated by weeks * 48
              // Chart will show years * 12
              for (let i = 0; i < MATH.annualy * yearBreakdown; i++) {
                chartData.push({ [myTier.tierName]: profit * MATH.weeklyAnnual * (i + 1), name: `Year ${i + 1}` });
              }
              // Breakdown by annually
              tierBreakEven = { breakEven: taxDeduction / (profit * MATH.weeklyAnnual), breakdown: 'Years' };
              break;
            default:
              chartData = [];
          }
        } else if (selectedTimeRange === TIMERANGE.monthly) {
          switch (selectedTimeBreakdown) {
            case TIMERANGE.weekly:
              //Tips are being calculated by months / 4
              //Chart will show 48 weeks

              for (let i = 0; i < MATH.weeklyAnnual; i++) {
                chartData.push({ [myTier.tierName]: (profit / MATH.weeksPerMonth) * (i + 1), name: `Week ${i + 1}` });
              }

              // Breakdown by months
              tierBreakEven = { breakEven: taxDeduction / (profit / MATH.weeksPerMonth), breakdown: 'Weeks' };
              break;
            case TIMERANGE.monthly:
              //Tips are being calculated by months
              //Chart will show 12 Months * 4
              for (let i = 0; i < MATH.monthlyAnnual * monthBreakdown; i++) {
                chartData.push({ [myTier.tierName]: profit * (i + 1), name: `Month ${i + 1}` });
              }
              // Breakdown by Months
              tierBreakEven = { breakEven: taxDeduction / profit, breakdown: 'Months' };
              break;
            case TIMERANGE.quarterly:
              //Tips are being calculated by months * 3
              //Chart will show 4 quarters * 12
              for (let i = 0; i < MATH.quarterlyAnnual * quarterBreakdown; i++) {
                chartData.push({ [myTier.tierName]: profit * MATH.monthlyQuarterly * (i + 1), name: `Quarter ${i + 1}` });
              }
              // Breakdown by quarters
              tierBreakEven = { breakEven: taxDeduction / (profit * MATH.monthlyQuarterly), breakdown: 'Quarters' };
              break;
            case TIMERANGE.annual:
              //Tips are being calculated by months * 12
              // Chart will show years * 12
              for (let i = 0; i < MATH.annualy * yearBreakdown; i++) {
                chartData.push({ [myTier.tierName]: profit * MATH.monthlyAnnual * (i + 1), name: `Year ${i + 1}` });
              }
              // Breakdown by annually
              tierBreakEven = { breakEven: taxDeduction / (profit * MATH.monthlyAnnual), breakdown: 'Years' };
              break;
            default:
              chartData = [];
          }
        }

        //////////////////////////////////////////////////////
        //
        // Next Tier Information
        //
        //////////////////////////////////////////////////////
        //If the mode is capital then we will figure it out based on the next tier

        if (foundTier !== -1 && foundTier !== tiers.length - 1) {
          const nextTier = tiers[foundTier + 1];

          xAxis.push({ key: nextTier.tierName });

          //We will need to get the average tip cost
          const nextTiertipCosts = selectedTips.map((tip) => (tip[nextTier.id] ? (tip[nextTier.id] / tip.tipsPerPack) * tip.amount : 0));
          const nextTiertipTotal = nextTiertipCosts.reduce((sum, price) => sum + price, 0);
          const nextTiertipAverage = nextTiertipTotal / roiTipCount;

          //Calculate the other costs
          const nextTierotherCosts = selectedTips.map((tip) => (tip.other ? (tip.other ?? 0) * tip.amount : 0));

          const nextTierotherTotal = nextTierotherCosts.reduce((sum, price) => sum + price, 0);
          const nextTierotherAverage = nextTierotherTotal / roiTipCount;

          //We will then need to figure out the average sales for all of the treatments
          const nextTiertreatmentSales = selectedTips.map((tip) => (tip.treatmentPrice ? tip.treatmentPrice * tip.amount : 0));
          const nextTiertreatmentTotal = nextTiertreatmentSales.reduce((sum, price) => sum + price, 0);
          const nextTiertreatmentAverage = nextTiertreatmentTotal / roiTipCount;

          const nextTiertipCost = nextTiertipAverage * roiTipCount;
          const nextTierloanPayment = selectedTimeRange === TIMERANGE.weekly ? monthlyPayment / 4 : monthlyPayment;
          const nextTiersales = nextTiertreatmentAverage * roiTipCount;
          const nextTierotherCost = nextTierotherAverage * roiTipCount;
          const nextTierprofit = nextTiersales - nextTiertipCost - nextTierloanPayment - nextTierotherCost;

          ///////////////////////////////////////////////////////////////////////////////////////////
          //
          //Now that we have the calculations we will next need to figure out how they are being calculated
          // Selected Time Range - Weekly or Monthly
          // We will need to make adjustments accorenlty
          //
          ///////////////////////////////////////////////////////////////////////////////////////////

          let nextTierBreakEven = {};

          if (selectedTimeRange === TIMERANGE.weekly) {
            //Tips are being calculated by weeks

            switch (selectedTimeBreakdown) {
              case TIMERANGE.weekly:
                //Tips are being calculated by weeks
                //profits * 1  mapped 48 times for the values
                //Chart will show 48 weeks
                for (let i = 0; i < MATH.weeklyAnnual; i++) {
                  chartData[i][nextTier.tierName] = nextTierprofit * (i + 1);
                }

                // Breakdown by weeks
                nextTierBreakEven = { breakEven: taxDeduction / nextTierprofit, breakdown: 'Weeks' };
                break;
              case TIMERANGE.monthly:
                //Tips are being calculated by weeks * 4
                //Chart will show 12 Months * 4
                for (let i = 0; i < MATH.monthlyAnnual * monthBreakdown; i++) {
                  chartData[i][nextTier.tierName] = nextTierprofit * MATH.weeklyMonthly * (i + 1);
                }
                // Breakdown by Months
                nextTierBreakEven = { breakEven: taxDeduction / (nextTierprofit * MATH.weeklyMonthly), breakdown: 'Months' };
                break;
              case TIMERANGE.quarterly:
                //Tips are being calculated by weeks * 12
                //Chart will show 4 quarters * 12
                for (let i = 0; i < MATH.quarterlyAnnual * quarterBreakdown; i++) {
                  chartData[i][nextTier.tierName] = nextTierprofit * MATH.weeklyQuarterly * (i + 1);
                }
                // Breakdown by quarters
                nextTierBreakEven = { breakEven: taxDeduction / (nextTierprofit * MATH.weeklyQuarterly), breakdown: 'Quarters' };
                break;
              case TIMERANGE.annual:
                //Tips are being calculated by weeks * 48
                // Chart will show years * 12
                for (let i = 0; i < MATH.annualy * yearBreakdown; i++) {
                  chartData[i][nextTier.tierName] = nextTierprofit * MATH.weeklyAnnual * (i + 1);
                }
                // Breakdown by annually
                nextTierBreakEven = { breakEven: taxDeduction / (nextTierprofit * MATH.weeklyAnnual), breakdown: 'Years' };
                break;
              default:
                chartData = [];
            }
          } else if (selectedTimeRange === TIMERANGE.monthly) {
            switch (selectedTimeBreakdown) {
              case TIMERANGE.weekly:
                //Tips are being calculated by months / 4
                //Chart will show 48 weeks

                for (let i = 0; i < MATH.weeklyAnnual; i++) {
                  chartData[i][nextTier.tierName] = (nextTierprofit / MATH.weeksPerMonth) * (i + 1);
                }

                // Breakdown by months
                nextTierBreakEven = { breakEven: taxDeduction / (profit / MATH.weeksPerMonth), breakdown: 'Weeks' };
                break;
              case TIMERANGE.monthly:
                //Tips are being calculated by months
                //Chart will show 12 Months * 4
                for (let i = 0; i < MATH.monthlyAnnual * monthBreakdown; i++) {
                  chartData[i][nextTier.tierName] = nextTierprofit * (i + 1);
                }
                // Breakdown by Months
                nextTierBreakEven = { breakEven: taxDeduction / nextTierprofit, breakdown: 'Months' };
                break;
              case TIMERANGE.quarterly:
                //Tips are being calculated by months * 3
                //Chart will show 4 quarters * 12
                for (let i = 0; i < MATH.quarterlyAnnual * quarterBreakdown; i++) {
                  chartData[i][nextTier.tierName] = nextTierprofit * MATH.monthlyQuarterly * (i + 1);
                }
                // Breakdown by quarters
                nextTierBreakEven = { breakEven: taxDeduction / (nextTierprofit * MATH.monthlyQuarterly), breakdown: 'Quarters' };
                break;
              case TIMERANGE.annual:
                //Tips are being calculated by months * 12
                // Chart will show years * 12
                for (let i = 0; i < MATH.annualy * yearBreakdown; i++) {
                  chartData[i][nextTier.tierName] = nextTierprofit * MATH.monthlyAnnual * (i + 1);
                }
                // Breakdown by annually
                nextTierBreakEven = { breakEven: taxDeduction / (nextTierprofit * MATH.monthlyAnnual), breakdown: 'Years' };
                break;
              default:
                chartData = [];
            }
          }
          setNextTierBreakEven({ tier: nextTier.tierName, ...nextTierBreakEven });
        }

        setCurrentTierBreakEven({ tier: myTier.tierName, ...tierBreakEven });

        setChartXData([...xAxis]);

        setChartData([...chartData]);
        setPDfCapitalDataReady(true);
      }
    }
    return () => {};
  }, [selectedTier, tiers, selectedTips, roiTipCount, tiersLoading, systemCosts, selectedTimeBreakdown, selectedTimeRange, clinicTaxBraket, monthlyPayment, roiMode]);

  /////////////////////////////////////////////
  //
  // Calculate the CONSUMER Chart Data Profits
  // >> Based on the amount of tips calculated
  // -  We will also need to know how we are calculating out tips WEEKLY OR MONTHLY
  //  - Need to figure out current tier
  //  - Need to figure out next tier up if not diamond
  //  - We will then need to breakout the information based on the next SELECTED TIME RANGE BREAKDOWN
  //  - Need to adjust based on the viewing information
  //
  /////////////////////////////////////////////

  useEffect(() => {
    /////////////////////////////////////////////////////////////
    //
    // Function to generate all of the different profits
    //
    /////////////////////////////////////////////////////////////

    const getProfits = (currentTier) => {
      let profit = 0;

      /////////////////////////////////////////////////////////////////
      //
      // Regular profits
      //
      /////////////////////////////////////////////////////////////////

      //Figure out the total of the tip costs
      const tipCosts = selectedTips.map((tip) => (currentTier ? (tip[currentTier.id] ? (tip[currentTier.id] / tip.tipsPerPack) * tip.amount : 0) : 0));
      const tipTotal = tipCosts.reduce((sum, price) => sum + price, 0);

      //Figure out the other costs
      const otherCosts = selectedTips.map((tip) => (tip.other ? (tip.other ?? 0) * tip.amount : 0));
      const otherTotal = otherCosts.reduce((sum, price) => sum + price, 0);

      //Figure out the total sales
      const treatmentSales = selectedTips.map((tip) => (tip.treatmentPrice ? tip.treatmentPrice * tip.amount : 0));
      const treatmentTotal = treatmentSales.reduce((sum, price) => sum + price, 0);

      // Figure out the cost of everything
      const regularCost = tipTotal + otherTotal;

      //Figure out the regular profit
      const regularProfit = treatmentTotal - tipTotal - otherTotal;
      //console.log('this is my regular profits ---> ', regularProfit);

      /////////////////////////////////////////////////////////////////
      //
      // Growth profits
      //
      /////////////////////////////////////////////////////////////////
      //Figure out the total of the growth tip costs
      const tipGrowthCosts = growthTips.map((tip) => (currentTier ? (tip[currentTier.id] ? (tip[currentTier.id] / tip.tipsPerPack) * tip.amount : 0) : 0));
      const tipGrothTotal = tipGrowthCosts.reduce((sum, price) => sum + price, 0);

      //Figure out the growth other costs
      const otherGrowthCosts = growthTips.map((tip) => (tip.other ? (tip.other ?? 0) * tip.amount : 0));
      const otherGrowthTotal = otherGrowthCosts.reduce((sum, price) => sum + price, 0);

      //Figure out the total sales
      const treatmentGrowthSales = growthTips.map((tip) => (tip.treatmentPrice ? tip.treatmentPrice * tip.amount : 0));
      const treatmentGrowthTotal = treatmentGrowthSales.reduce((sum, price) => sum + price, 0);

      //Figure out Growth Cost
      const growthCost = tipGrothTotal + otherGrowthTotal;

      const growthProfit = treatmentGrowthTotal - tipGrothTotal - otherGrowthTotal;

      profit = regularProfit + growthProfit;
      const cost = regularCost + growthCost;
      const revenue = treatmentTotal + treatmentGrowthTotal;
      const spend = tipTotal + tipGrothTotal;

      return { profit, cost, revenue, spend, regularProfit, tipTotal, regularCost, treatmentTotal };
    };
    if (selectedTier && !tiersLoading && roiMode === MODE.consumer) {
      //const foundTier = tiers.findIndex((tier) => tier.id === selectedTier);
      //console.log('In IF 1');
      const myTier = tiers.find((tier) => tier.id === selectedTier);

      if (roiTipCount > 0) {
        //If tip count is less then 6 we will then only add up to 12
        let growthDifference = {};
        //////////////////////////////////////////////////////
        //
        // Current Tier Information
        //
        //////////////////////////////////////////////////////
        //We will need to get the average tip cost
        const tipCosts = selectedTips.map((tip) => (tip[selectedTier] ? (tip[selectedTier] / tip.tipsPerPack) * tip.amount : 0));
        const tipTotal = tipCosts.reduce((sum, price) => sum + price, 0);
        const tipAverage = tipTotal / roiTipCount;

        //Calculate the other costs
        const otherCosts = selectedTips.map((tip) => (tip.other ? (tip.other ?? 0) * tip.amount : 0));

        const otherTotal = otherCosts.reduce((sum, price) => sum + price, 0);
        const otherAverage = otherTotal / roiTipCount;

        //We will then need to figure out the average sales for all of the treatments
        const treatmentSales = selectedTips.map((tip) => (tip.treatmentPrice ? tip.treatmentPrice * tip.amount : 0));
        const treatmentTotal = treatmentSales.reduce((sum, price) => sum + price, 0);
        const treatmentAverage = treatmentTotal / roiTipCount;

        const tipCost = tipAverage * roiTipCount;
        const loanPayment = selectedTimeRange === TIMERANGE.weekly ? monthlyPayment / 4 : monthlyPayment;
        const sales = treatmentAverage * roiTipCount;
        const otherCost = otherAverage * roiTipCount;
        const profit = sales - tipCost - loanPayment - otherCost;

        //console.log('this is my profit ----> ', profit);

        ///////////////////////////////////////////////////////////////////////////////////////////
        //
        //Now that we have the calculations we will next need to figure out how they are being calculated
        // Selected Time Range - Weekly or Monthly
        // We will need to make adjustments accorenlty
        //
        ///////////////////////////////////////////////////////////////////////////////////////////
        const currentProfits = 'Current Profits';
        const growthProfits = 'Potential Profits';

        let xAxis = [{ key: currentProfits }];

        let chartData = [];
        let tierBreakEven = {};

        const monthBreakdown = 4;
        const quarterBreakdown = 8;
        const yearBreakdown = 12;

        let myProfit = 0;
        let myCost = 0;
        let myRevenue = 0;
        let regualrProfit = 0;
        let growthProfit = 0;
        let growthRevenue = 0;
        let growthCost = 0;

        let totalSpend = currentSpend ?? 0;
        let currentTier = tiers.find((t) => t.id === selectedTier);
        let nextPossibleTier = nextTier;
        let nextTierSpendMin = nextPossibleTier.min;

        if (selectedTimeRange === TIMERANGE.weekly) {
          //Tips are being calculated by weeks

          switch (selectedTimeBreakdown) {
            case TIMERANGE.weekly:
              //Tips are being calculated by weeks
              //profits * 1  mapped 48 times for the values
              //Chart will show 48 weeks
              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalSpend <= nextTierSpendMin) {
                  let values = getProfits(currentTier);
                  //console.log('these are my values ---->', values);

                  totalSpend = totalSpend + values.tipTotal;
                  myProfit = myProfit + values.regularProfit;
                  regualrProfit = values.regularProfit;
                  myCost = myCost + values.regularCost;
                  myRevenue = myRevenue + values.treatmentTotal;

                  chartData.push({ name: `Week ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost, ...values });
                } else {
                  currentTier = nextPossibleTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendMin = nextPossibleTier.min;

                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.tipTotal;
                  myProfit = myProfit + values.regularProfit;
                  regualrProfit = values.regularProfit;
                  myCost = myCost + values.regularCost;
                  myRevenue = myRevenue + values.treatmentTotal;

                  chartData.push({ name: `Week ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName });
                }
              }

              //After the six months we will now have a new base line for what tier we will be in based on the treatments
              totalSpend = 0;
              for (let i = MATH.weeklyAnnual / 2; i < MATH.weeklyAnnual; i++) {
                let values = getProfits(currentTier);

                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////

                totalSpend = totalSpend + values.tipTotal;
                myProfit = myProfit + values.regularProfit;
                regualrProfit = values.regularProfit;
                myCost = myCost + values.regularCost;
                myRevenue = myRevenue + values.treatmentTotal;
                chartData.push({ name: `Week ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName });
              }

              /////////////////////////////////////////////////////////////////
              //
              // Set the breakdown costs calculations
              //
              /////////////////////////////////////////////////////////////////
              setConsumerTreatmentCost(myCost / MATH.weeklyAnnual);
              setconsumerPracticeRevenue(myRevenue / MATH.weeklyAnnual);
              setConsumerTreatmentProfit(myProfit / MATH.weeklyAnnual);

              setConsumerAnnualPracticeRevenue(myRevenue);
              setConsumerAnnualTreatmentCost(myCost);
              setConsumerAnnualTreatmentProfit(myProfit);

              break;
            case TIMERANGE.monthly:
              //Tips are being calculated by weeks * 4
              //Chart will show 12 Months * 4
              for (let i = 0; i < MATH.monthlyAnnual / 2; i++) {
                if (totalSpend <= nextTierSpendMin) {
                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.tipTotal * MATH.weeklyMonthly;
                  myProfit = myProfit + values.regularProfit * MATH.weeklyMonthly;
                  regualrProfit = values.regularProfit * MATH.weeklyMonthly;

                  myCost = myCost + values.regularCost * MATH.weeklyMonthly;
                  myRevenue = myRevenue + values.treatmentTotal * MATH.weeklyMonthly;

                  chartData.push({ name: `Month ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost });
                } else {
                  currentTier = nextPossibleTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendMin = nextPossibleTier.min;

                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.tipTotal * MATH.weeklyMonthly;
                  myProfit = myProfit + values.regularProfit * MATH.weeklyMonthly;
                  regualrProfit = values.regularProfit * MATH.weeklyMonthly;

                  myCost = myCost + values.regularCost * MATH.weeklyMonthly;
                  myRevenue = myRevenue + values.treatmentTotal * MATH.weeklyMonthly;

                  chartData.push({ name: `Month ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost });
                }
              }

              totalSpend = 0;
              for (let i = MATH.monthlyAnnual / 2; i < MATH.monthlyAnnual * monthBreakdown; i++) {
                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////
                let values = getProfits(currentTier);

                totalSpend = totalSpend + values.tipTotal * MATH.weeklyMonthly;
                myProfit = myProfit + values.regularProfit * MATH.weeklyMonthly;
                regualrProfit = values.regularProfit * MATH.weeklyMonthly;

                myCost = myCost + values.regularCost * MATH.weeklyMonthly;
                myRevenue = myRevenue + values.treatmentTotal * MATH.weeklyMonthly;

                chartData.push({ name: `Month ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost });
              }

              /////////////////////////////////////////////////////////////////
              //
              // Set the breakdown costs calculations
              //
              /////////////////////////////////////////////////////////////////
              setConsumerTreatmentCost(myCost / monthBreakdown / MATH.monthlyAnnual);
              setconsumerPracticeRevenue(myRevenue / monthBreakdown / MATH.monthlyAnnual);
              setConsumerTreatmentProfit(myProfit / monthBreakdown / MATH.monthlyAnnual);

              setConsumerAnnualPracticeRevenue(myRevenue / monthBreakdown);
              setConsumerAnnualTreatmentCost(myCost / monthBreakdown);
              setConsumerAnnualTreatmentProfit(myProfit / monthBreakdown);

              break;
            case TIMERANGE.quarterly:
              //Tips are being calculated by weeks * 12
              //Chart will show 4 quarters * 12
              //Figure out if they upgrade in six months
              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalSpend <= nextTierSpendMin) {
                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.regularCost;
                } else {
                  currentTier = nextPossibleTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendMin = nextPossibleTier.min;

                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.regularCost;
                }
              }

              for (let i = 0; i < MATH.quarterlyAnnual * quarterBreakdown; i++) {
                //chartData[i][nextTier.tierName] = nextTierprofit * MATH.weeklyQuarterly * (i + 1);
                let values = getProfits(currentTier);

                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////

                /* setconsumerTreatmentGrowthCost(values.cost * MATH.weeklyQuarterly);
                setconsumerPracticeGrowthRevenue(values.revenue * MATH.weeklyQuarterly);
                setconsumerTreatmentGrowthProfit(values.profit * MATH.weeklyQuarterly); */
                totalSpend = totalSpend + values.tipTotal * MATH.weeklyQuarterly;
                myProfit = myProfit + values.regularProfit * MATH.weeklyQuarterly;
                regualrProfit = values.regularProfit * MATH.weeklyQuarterly;

                myCost = myCost + values.regularCost * MATH.weeklyQuarterly;
                myRevenue = myRevenue + values.treatmentTotal * MATH.weeklyQuarterly;

                chartData.push({ name: `Quarter ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost });
              }

              /////////////////////////////////////////////////////////////////
              //
              // Set the breakdown costs calculations
              //
              /////////////////////////////////////////////////////////////////
              setConsumerTreatmentCost(myCost / quarterBreakdown / MATH.quarterlyAnnual);
              setconsumerPracticeRevenue(myRevenue / quarterBreakdown / MATH.quarterlyAnnual);
              setConsumerTreatmentProfit(myProfit / quarterBreakdown / MATH.quarterlyAnnual);

              setConsumerAnnualPracticeRevenue(myRevenue / quarterBreakdown);
              setConsumerAnnualTreatmentCost(myCost / quarterBreakdown);
              setConsumerAnnualTreatmentProfit(myProfit / quarterBreakdown);

              break;
            case TIMERANGE.annual:
              //Tips are being calculated by weeks * 48
              // Chart will show years * 12
              //Figure out if they upgrade in six months
              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalSpend <= nextTierSpendMin) {
                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.regularCost;
                } else {
                  currentTier = nextPossibleTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendMin = nextPossibleTier.min;

                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.regularCost;
                }
              }

              for (let i = 0; i < MATH.annualy * yearBreakdown; i++) {
                let values = getProfits(currentTier);

                totalSpend = totalSpend + values.tipTotal * MATH.weeklyAnnual;
                myProfit = myProfit + values.regularProfit * MATH.weeklyAnnual;
                regualrProfit = values.regularProfit * MATH.weeklyAnnual;

                myCost = myCost + values.regularCost * MATH.weeklyAnnual;
                myRevenue = myRevenue + values.treatmentTotal * MATH.weeklyAnnual;

                chartData.push({ name: `Year ${i + 1}`, [currentProfits]: myProfit, baseTier: myTier.tierName, regularTip: tipCost });
              }

              /////////////////////////////////////////////////////////////////
              //
              // Set the breakdown costs calculations
              //
              /////////////////////////////////////////////////////////////////
              setConsumerTreatmentCost(myCost / yearBreakdown);
              setconsumerPracticeRevenue(myRevenue / yearBreakdown);
              setConsumerTreatmentProfit(myProfit / yearBreakdown);

              setConsumerAnnualPracticeRevenue(myRevenue / yearBreakdown);
              setConsumerAnnualTreatmentCost(myCost / yearBreakdown);
              setConsumerAnnualTreatmentProfit(myProfit / yearBreakdown);

              break;
            default:
              chartData = [];
          }
        } else if (selectedTimeRange === TIMERANGE.monthly) {
          switch (selectedTimeBreakdown) {
            case TIMERANGE.weekly:
              //Tips are being calculated by months / 4
              //Chart will show 48 weeks

              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalSpend <= nextTierSpendMin) {
                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.tipTotal / MATH.weeksPerMonth;
                  myProfit = myProfit + values.regularProfit / MATH.weeksPerMonth;
                  regualrProfit = values.regularProfit / MATH.weeksPerMonth;

                  myCost = myCost + values.regularCost / MATH.weeksPerMonth;
                  myRevenue = myRevenue + values.treatmentTotal / MATH.weeksPerMonth;

                  chartData.push({ name: `Week ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost, ...values });
                } else {
                  currentTier = nextPossibleTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendMin = nextPossibleTier.min;

                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.tipTotal / MATH.weeksPerMonth;
                  myProfit = myProfit + values.regularProfit / MATH.weeksPerMonth;
                  regualrProfit = values.regularProfit / MATH.weeksPerMonth;

                  myCost = myCost + values.regularCost / MATH.weeksPerMonth;
                  myRevenue = myRevenue + values.treatmentTotal / MATH.weeksPerMonth;

                  chartData.push({ name: `Week ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName });
                }

                /* const myProfit = (profit / MATH.weeksPerMonth) * (i + 1);
                regualrProfit = profit / MATH.weeksPerMonth;
                chartData.push({ name: `Week ${i + 1}`, [currentProfits]: myProfit, baseTier: myTier.tierName, regularTip: tipCost }); */
              }

              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                let values = getProfits(currentTier);

                totalSpend = totalSpend + values.tipTotal / MATH.weeksPerMonth;
                myProfit = myProfit + values.regularProfit / MATH.weeksPerMonth;
                regualrProfit = values.regularProfit / MATH.weeksPerMonth;

                myCost = myCost + values.regularCost / MATH.weeksPerMonth;
                myRevenue = myRevenue + values.treatmentTotal / MATH.weeksPerMonth;

                chartData.push({ name: `Week ${i + 1}`, [currentProfits]: myProfit, baseTier: myTier.tierName, regularTip: tipCost });
              }

              /////////////////////////////////////////////////////////////////
              //
              // Set the breakdown costs calculations
              //
              /////////////////////////////////////////////////////////////////
              setConsumerTreatmentCost(myCost / MATH.weeklyAnnual);
              setconsumerPracticeRevenue(myRevenue / MATH.weeklyAnnual);
              setConsumerTreatmentProfit(myProfit / MATH.weeklyAnnual);

              setConsumerAnnualPracticeRevenue(myRevenue);
              setConsumerAnnualTreatmentCost(myCost);
              setConsumerAnnualTreatmentProfit(myProfit);

              break;
            case TIMERANGE.monthly:
              //Tips are being calculated by months
              //Chart will show 12 Months * 4
              for (let i = 0; i < MATH.monthlyAnnual / 2; i++) {
                if (totalSpend <= nextTierSpendMin) {
                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.tipTotal;
                  myProfit = myProfit + values.regularProfit;
                  regualrProfit = values.regularProfit;

                  myCost = myCost + values.regularCost;
                  myRevenue = myRevenue + values.treatmentTotal;

                  chartData.push({ name: `Month ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost });
                } else {
                  currentTier = nextPossibleTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendMin = nextPossibleTier.min;

                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.tipTotal;
                  myProfit = myProfit + values.regularProfit;
                  regualrProfit = values.regularProfit;

                  myCost = myCost + values.regularCost;
                  myRevenue = myRevenue + values.treatmentTotal;

                  chartData.push({ name: `Month ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost });
                }

                /* const myProfit = profit * (i + 1);
                regualrProfit = profit;
                chartData.push({ name: `Month ${i + 1}`, [currentProfits]: myProfit, baseTier: myTier.tierName, regularTip: tipCost }); */
              }

              for (let i = MATH.monthlyAnnual / 2; i < MATH.monthlyAnnual * monthBreakdown; i++) {
                let values = getProfits(currentTier);

                myProfit = myProfit + values.regularProfit;
                regualrProfit = values.regularProfit;

                myCost = myCost + values.regularCost;
                myRevenue = myRevenue + values.treatmentTotal;

                chartData.push({ name: `Month ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost });
              }

              /////////////////////////////////////////////////////////////////
              //
              // Set the breakdown costs calculations
              //
              /////////////////////////////////////////////////////////////////
              setConsumerTreatmentCost(myCost / monthBreakdown / MATH.monthlyAnnual);
              setconsumerPracticeRevenue(myRevenue / monthBreakdown / MATH.monthlyAnnual);
              setConsumerTreatmentProfit(myProfit / monthBreakdown / MATH.monthlyAnnual);

              setConsumerAnnualPracticeRevenue(myRevenue / monthBreakdown);
              setConsumerAnnualTreatmentCost(myCost / monthBreakdown);
              setConsumerAnnualTreatmentProfit(myProfit / monthBreakdown);

              break;
            case TIMERANGE.quarterly:
              //Tips are being calculated by months * 3
              //Chart will show 4 quarters * 12
              //console.log('this is my current tier --->', currentTier);
              //Figure out if they upgrade in six months
              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalSpend <= nextTierSpendMin) {
                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.regularCost / 4;
                } else {
                  currentTier = nextPossibleTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendMin = nextPossibleTier.min;

                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.regularCost / 4;
                }
              }

              //console.log('this is my current tier --->', currentTier);

              for (let i = 0; i < MATH.quarterlyAnnual * quarterBreakdown; i++) {
                let values = getProfits(currentTier);

                myProfit = myProfit + values.regularProfit * MATH.monthlyQuarterly;
                regualrProfit = values.regularProfit * MATH.monthlyQuarterly;

                myCost = myCost + values.regularCost * MATH.monthlyQuarterly;
                myRevenue = myRevenue + values.treatmentTotal * MATH.monthlyQuarterly;

                chartData.push({ name: `Quarter ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost });
              }

              /////////////////////////////////////////////////////////////////
              //
              // Set the breakdown costs calculations
              //
              /////////////////////////////////////////////////////////////////
              setConsumerTreatmentCost(myCost / quarterBreakdown / MATH.quarterlyAnnual);
              setconsumerPracticeRevenue(myRevenue / quarterBreakdown / MATH.quarterlyAnnual);
              setConsumerTreatmentProfit(myProfit / quarterBreakdown / MATH.quarterlyAnnual);

              setConsumerAnnualPracticeRevenue(myRevenue / quarterBreakdown);
              setConsumerAnnualTreatmentCost(myCost / quarterBreakdown);
              setConsumerAnnualTreatmentProfit(myProfit / quarterBreakdown);

              break;
            case TIMERANGE.annual:
              //Tips are being calculated by months * 12
              // Chart will show years * 12

              //Figure out if they upgrade in six months
              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalSpend <= nextTierSpendMin) {
                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.regularCost / 4;
                } else {
                  currentTier = nextPossibleTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendMin = nextPossibleTier.min;

                  let values = getProfits(currentTier);

                  totalSpend = totalSpend + values.regularCost / 4;
                }
              }

              for (let i = 0; i < MATH.annualy * yearBreakdown; i++) {
                let values = getProfits(currentTier);

                myProfit = myProfit + values.regularProfit * MATH.monthlyAnnual;
                regualrProfit = values.regularProfit * MATH.monthlyAnnual;

                myCost = myCost + values.regularCost * MATH.monthlyAnnual;
                myRevenue = myRevenue + values.treatmentTotal * MATH.monthlyAnnual;

                chartData.push({ name: `Year ${i + 1}`, [currentProfits]: myProfit, baseTier: currentTier.tierName, regularTip: values.regularCost });
              }

              /////////////////////////////////////////////////////////////////
              //
              // Set the breakdown costs calculations
              //
              /////////////////////////////////////////////////////////////////
              setConsumerTreatmentCost(myCost / yearBreakdown / MATH.annualy);
              setconsumerPracticeRevenue(myRevenue / yearBreakdown / MATH.annualy);
              setConsumerTreatmentProfit(myProfit / yearBreakdown / MATH.annualy);

              setConsumerAnnualPracticeRevenue(myRevenue / yearBreakdown);
              setConsumerAnnualTreatmentCost(myCost / yearBreakdown);
              setConsumerAnnualTreatmentProfit(myProfit / yearBreakdown);

              break;
            default:
              chartData = [];
          }
        }

        //////////////////////////////////////////////////////
        //
        // Growth Tier Information
        //
        //////////////////////////////////////////////////////
        //If the mode is capital then we will figure it out based on the next tier

        /////////////////////////////////////////////////////////////////
        // Consumer Growth
        // Else we need to figure out the profit including the growth
        //
        /////////////////////////////////////////////////////////////////

        xAxis.push({ key: growthProfits });

        //We will need to go through the first six weeks and figure out when we will bump up to the different tiers
        let totalGrowthSpend = currentSpend ?? 0;
        let currentGrowthTier = tiers.find((t) => t.id === selectedTier);
        let nextPossibleGrowthTier = nextTier;
        let nextTierSpendGrowthMin = nextPossibleGrowthTier.min;

        //console.log('this is my current tier ----> ', currentTier, totalSpend);

        //From there on out we will be able to figure out the rest of the time frames.

        if (selectedTimeRange === TIMERANGE.weekly) {
          //Tips are being calculated by weeks

          switch (selectedTimeBreakdown) {
            case TIMERANGE.weekly:
              //Tips are being calculated by weeks
              //profits * 1  mapped 48 times for the values

              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalGrowthSpend <= nextTierSpendGrowthMin) {
                  let values = getProfits(currentGrowthTier);

                  //console.log('this is my growth tier ---> ', currentGrowthTier.tierName);
                  //console.log('this is my growth revenue ----> ', values);

                  totalGrowthSpend = totalGrowthSpend + values.spend;
                  //Start to build the growth Profit
                  growthProfit = growthProfit + values.profit;
                  growthRevenue = growthRevenue + values.revenue;
                  growthCost = growthCost + values.cost;

                  //console.log('this is my growth profit ---->', growthProfit);

                  chartData[i][growthProfits] = growthProfit;
                  chartData[i].growthTier = currentGrowthTier.tierName;
                  chartData[i].growthTipCost = values.cost;
                  chartData[i].growthTotalSpend = totalGrowthSpend;
                } else {
                  currentGrowthTier = nextPossibleGrowthTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentGrowthTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleGrowthTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendGrowthMin = nextPossibleGrowthTier.min;

                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend;
                  growthProfit = growthProfit + values.profit;
                  growthRevenue = growthRevenue + values.revenue;
                  growthCost = growthCost + values.cost;

                  chartData[i][growthProfits] = growthProfit;
                  chartData[i].growthTier = currentGrowthTier.tierName;
                  chartData[i].growthTipCost = values.cost;
                  chartData[i].growthTotalSpend = totalGrowthSpend;
                }
              }

              //After the six months we will now have a new base line for what tier we will be in based on the treatments

              //Chart will show 48 weeks we will minus 6 months off of this chart
              //console.log('this is my current tier for the next monht ---> ', currentTier);
              totalGrowthSpend = 0;
              for (let i = MATH.weeklyAnnual / 2; i < MATH.weeklyAnnual; i++) {
                let values = getProfits(currentGrowthTier);

                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////

                totalGrowthSpend = totalGrowthSpend + values.spend;
                growthProfit = growthProfit + values.profit;
                growthRevenue = growthRevenue + values.revenue;
                growthCost = growthCost + values.cost;

                // Sets the last tier cost
                //setconsumerTreatmentGrowthCost(values.cost);
                //setconsumerPracticeGrowthRevenue(values.revenue);
                //setconsumerTreatmentGrowthProfit(values.profit);

                chartData[i][growthProfits] = growthProfit;
                chartData[i].growthTier = currentGrowthTier.tierName;
                chartData[i].growthTipCost = values.cost;
                chartData[i].growthTotalSpend = totalGrowthSpend;
              }

              /////////////////////////////////////////////////////////////////
              //
              // Post the annual calculations
              //
              /////////////////////////////////////////////////////////////////
              setconsumerTreatmentGrowthCost(growthCost / MATH.weeklyAnnual);
              setconsumerPracticeGrowthRevenue(growthRevenue / MATH.weeklyAnnual);
              setconsumerTreatmentGrowthProfit(growthProfit / MATH.weeklyAnnual);

              // Profit Different By Week
              let values = getProfits(currentGrowthTier);
              //console.log('this is my Weekly current tier ----> ', currentTier);
              const clinicProfit = values.profit - regualrProfit;
              growthDifference = { growthDifference: clinicProfit, breakdown: 'Weekly' };
              setconsumerAnnualTreatmenGrowthtProfit(growthProfit);
              setconsumerAnnualTreatmentGrowthCost(growthCost);
              setconsumerAnnualPracticeGrowthRevenue(growthRevenue);

              break;
            case TIMERANGE.monthly:
              //Tips are being calculated by weeks * 4
              //We are going to go through the first 6 months
              //Figure out if they upgrade in six months
              for (let i = 0; i < MATH.monthlyAnnual / 2; i++) {
                if (totalGrowthSpend <= nextTierSpendGrowthMin) {
                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend * MATH.weeklyMonthly;
                  growthProfit = growthProfit + values.profit * MATH.weeklyMonthly;
                  growthRevenue = growthRevenue + values.revenue * MATH.weeklyMonthly;
                  growthCost = growthCost + values.cost * MATH.weeklyMonthly;

                  chartData[i][growthProfits] = growthProfit;
                  chartData[i].growthTier = currentGrowthTier.tierName;
                  chartData[i].growthTipCost = values.cost * MATH.weeklyMonthly;
                  chartData[i].growthTotalSpend = totalGrowthSpend;
                } else {
                  currentGrowthTier = nextPossibleGrowthTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentGrowthTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleGrowthTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendGrowthMin = nextPossibleGrowthTier.min;

                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend * MATH.weeklyMonthly;
                  growthProfit = growthProfit + values.profit * MATH.weeklyMonthly;
                  growthRevenue = growthRevenue + values.revenue * MATH.weeklyMonthly;
                  growthCost = growthCost + values.cost * MATH.weeklyMonthly;

                  chartData[i][growthProfits] = growthProfit;
                  chartData[i].growthTier = currentGrowthTier.tierName;
                  chartData[i].growthTipCost = values.cost * MATH.weeklyMonthly;
                  chartData[i].growthTotalSpend = totalGrowthSpend;
                }
              }
              //Now we will go through the rest of the months
              totalGrowthSpend = 0;
              for (let i = MATH.monthlyAnnual / 2; i < MATH.monthlyAnnual * monthBreakdown; i++) {
                let values = getProfits(currentGrowthTier);

                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////

                /* setconsumerTreatmentGrowthCost(values.cost * MATH.weeklyMonthly);
                setconsumerPracticeGrowthRevenue(values.revenue * MATH.weeklyMonthly);
                setconsumerTreatmentGrowthProfit(values.profit * MATH.weeklyMonthly); */

                totalGrowthSpend = totalGrowthSpend + values.spend * MATH.weeklyMonthly;
                growthProfit = growthProfit + values.profit * MATH.weeklyMonthly;
                growthRevenue = growthRevenue + values.revenue * MATH.weeklyMonthly;
                growthCost = growthCost + values.cost * MATH.weeklyMonthly;

                chartData[i][growthProfits] = growthProfit;
                chartData[i].growthTier = currentGrowthTier.tierName;
                chartData[i].growthTipCost = values.cost * MATH.weeklyMonthly;
                chartData[i].growthTotalSpend = totalGrowthSpend;
              }

              /////////////////////////////////////////////////////////////////
              //
              // Post the annual calculations
              //
              /////////////////////////////////////////////////////////////////
              setconsumerTreatmentGrowthCost(growthCost / monthBreakdown / MATH.monthlyAnnual);
              setconsumerPracticeGrowthRevenue(growthRevenue / monthBreakdown / MATH.monthlyAnnual);
              setconsumerTreatmentGrowthProfit(growthProfit / monthBreakdown / MATH.monthlyAnnual);

              // Profit Different By Week
              //console.log('this is my Monthlycurrent tier ----> ', currentTier);
              let monthValues = getProfits(currentGrowthTier);
              const clinicMonthProfit = monthValues.profit * MATH.weeklyMonthly - regualrProfit;
              growthDifference = { growthDifference: clinicMonthProfit, breakdown: 'Monthly' };
              setconsumerAnnualTreatmenGrowthtProfit(growthProfit / monthBreakdown);
              setconsumerAnnualTreatmentGrowthCost(growthCost / monthBreakdown);
              setconsumerAnnualPracticeGrowthRevenue(growthRevenue / monthBreakdown);
              // Breakdown by Months
              //nextTierBreakEven = { breakEven: taxDeduction / (nextTierprofit * MATH.weeklyMonthly), breakdown: 'Months' };
              break;
            case TIMERANGE.quarterly:
              //Tips are being calculated by weeks * 12
              //Chart will show 4 quarters * 12
              //Figure out if they upgrade in six months
              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalGrowthSpend <= nextTierSpendGrowthMin) {
                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend;
                } else {
                  currentGrowthTier = nextPossibleGrowthTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentGrowthTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleGrowthTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendGrowthMin = nextPossibleGrowthTier.min;

                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend;
                }
              }

              for (let i = 0; i < MATH.quarterlyAnnual * quarterBreakdown; i++) {
                //chartData[i][nextTier.tierName] = nextTierprofit * MATH.weeklyQuarterly * (i + 1);
                let values = getProfits(currentGrowthTier);

                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////

                /* setconsumerTreatmentGrowthCost(values.cost * MATH.weeklyQuarterly);
                setconsumerPracticeGrowthRevenue(values.revenue * MATH.weeklyQuarterly);
                setconsumerTreatmentGrowthProfit(values.profit * MATH.weeklyQuarterly); */

                totalGrowthSpend = totalGrowthSpend + values.spend * MATH.weeklyQuarterly;
                growthProfit = growthProfit + values.profit * MATH.weeklyQuarterly;
                growthRevenue = growthRevenue + values.revenue * MATH.weeklyQuarterly;
                growthCost = growthCost + values.cost * MATH.weeklyQuarterly;

                chartData[i][growthProfits] = growthProfit;
                chartData[i].growthTier = currentGrowthTier.tierName;
                chartData[i].growthTipCost = values.cost * MATH.weeklyQuarterly;
                chartData[i].growthTotalSpend = totalGrowthSpend;
              }
              // Breakdown by quarters
              /////////////////////////////////////////////////////////////////
              //
              // Post the annual calculations
              //
              /////////////////////////////////////////////////////////////////
              setconsumerTreatmentGrowthCost(growthCost / quarterBreakdown / MATH.quarterlyAnnual);
              setconsumerPracticeGrowthRevenue(growthRevenue / quarterBreakdown / MATH.quarterlyAnnual);
              setconsumerTreatmentGrowthProfit(growthProfit / quarterBreakdown / MATH.quarterlyAnnual);

              // Profit Different By Week
              let quarterValues = getProfits(currentGrowthTier);
              //console.log('this is my current Quartly tier ----> ', currentGrowthTier);
              const clinicQuarterProfit = quarterValues.profit * MATH.weeklyQuarterly - regualrProfit;
              growthDifference = { growthDifference: clinicQuarterProfit, breakdown: 'Quartly' };
              setconsumerAnnualTreatmenGrowthtProfit(growthProfit / quarterBreakdown);
              setconsumerAnnualTreatmentGrowthCost(growthCost / quarterBreakdown);
              setconsumerAnnualPracticeGrowthRevenue(growthRevenue / quarterBreakdown);

              //nextTierBreakEven = { breakEven: taxDeduction / (nextTierprofit * MATH.weeklyQuarterly), breakdown: 'Quarters' };
              break;
            case TIMERANGE.annual:
              //Tips are being calculated by weeks * 48
              // Chart will show years * 12

              //We will see if they upgrade in 6 months

              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalGrowthSpend <= nextTierSpendGrowthMin) {
                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend;
                } else {
                  currentGrowthTier = nextPossibleGrowthTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentGrowthTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleGrowthTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendGrowthMin = nextPossibleGrowthTier.min;

                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend;
                }
              }

              for (let i = 0; i < MATH.annualy * yearBreakdown; i++) {
                //chartData[i][nextTier.tierName] = nextTierprofit * MATH.weeklyAnnual * (i + 1);
                let values = getProfits(currentGrowthTier);

                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////

                setconsumerTreatmentGrowthCost(values.cost * MATH.weeklyAnnual);
                setconsumerPracticeGrowthRevenue(values.revenue * MATH.weeklyAnnual);
                setconsumerTreatmentGrowthProfit(values.profit * MATH.weeklyAnnual);

                totalGrowthSpend = totalGrowthSpend + values.spend * MATH.weeklyAnnual;
                growthProfit = growthProfit + values.profit * MATH.weeklyAnnual;
                growthRevenue = growthRevenue + values.revenue * MATH.weeklyAnnual;
                growthCost = growthCost + values.cost * MATH.weeklyAnnual;

                chartData[i][growthProfits] = growthProfit;
                chartData[i].growthTier = currentGrowthTier.tierName;
                chartData[i].growthTipCost = values.cost * MATH.weeklyAnnual;
                chartData[i].growthTotalSpend = totalGrowthSpend;
              }

              // Profit Different By Week
              let annualValues = getProfits(currentGrowthTier);
              //console.log('this is my ANNUAL current tier ----> ', currentTier);
              const clinicAnnualProfit = annualValues.profit * MATH.weeklyAnnual - regualrProfit;
              growthDifference = { growthDifference: clinicAnnualProfit, breakdown: 'Yearly' };
              setconsumerAnnualTreatmenGrowthtProfit(growthProfit / yearBreakdown);
              setconsumerAnnualTreatmentGrowthCost(growthCost / yearBreakdown);
              setconsumerAnnualPracticeGrowthRevenue(growthRevenue / yearBreakdown);
              // Breakdown by annually
              //nextTierBreakEven = { breakEven: taxDeduction / (nextTierprofit * MATH.weeklyAnnual), breakdown: 'Years' };
              break;
            default:
              chartData = [];
          }
        } else if (selectedTimeRange === TIMERANGE.monthly) {
          switch (selectedTimeBreakdown) {
            case TIMERANGE.weekly:
              //Tips are being calculated by months / 4
              //Chart will show 48 weeks

              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalGrowthSpend <= nextTierSpendGrowthMin) {
                  let values = getProfits(currentGrowthTier);

                  //console.log('this is my revenue ----> ', values);

                  totalGrowthSpend = totalGrowthSpend + values.spend / MATH.weeksPerMonth;
                  //Start to build the growth Profit
                  growthProfit = growthProfit + values.profit / MATH.weeksPerMonth;
                  growthRevenue = growthRevenue + values.revenue / MATH.weeksPerMonth;
                  growthCost = growthCost + values.cost / MATH.weeksPerMonth;

                  chartData[i][growthProfits] = growthProfit;
                  chartData[i].growthTier = currentGrowthTier.tierName;
                  chartData[i].growthTipCost = values.cost / MATH.weeksPerMonth;
                  chartData[i].growthTotalSpend = totalGrowthSpend;
                } else {
                  currentGrowthTier = nextPossibleGrowthTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentGrowthTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleGrowthTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendGrowthMin = nextPossibleGrowthTier.min;

                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend / MATH.weeksPerMonth;
                  growthProfit = growthProfit + values.profit / MATH.weeksPerMonth;
                  growthRevenue = growthRevenue + values.revenue / MATH.weeksPerMonth;
                  growthCost = growthCost + values.cost / MATH.weeksPerMonth;

                  chartData[i][growthProfits] = growthProfit;
                  chartData[i].growthTier = currentGrowthTier.tierName;
                  chartData[i].growthTipCost = values.cost / MATH.weeksPerMonth;
                  chartData[i].growthTotalSpend = totalGrowthSpend;
                }
              }

              //After the six months we will now have a new base line for what tier we will be in based on the treatments

              //Chart will show 48 weeks we will minus 6 months off of this chart
              totalGrowthSpend = 0;
              for (let i = MATH.weeklyAnnual / 2; i < MATH.weeklyAnnual; i++) {
                let values = getProfits(currentGrowthTier);

                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////

                totalGrowthSpend = totalGrowthSpend + values.spend / MATH.weeksPerMonth;
                growthProfit = growthProfit + values.profit / MATH.weeksPerMonth;
                growthRevenue = growthRevenue + values.revenue / MATH.weeksPerMonth;
                growthCost = growthCost + values.cost / MATH.weeksPerMonth;

                chartData[i][growthProfits] = growthProfit;
                chartData[i].growthTier = currentGrowthTier.tierName;
                chartData[i].growthTipCost = values.cost / MATH.weeksPerMonth;
                chartData[i].growthTotalSpend = totalGrowthSpend;
              }

              /////////////////////////////////////////////////////////////////
              //
              // Post the annual calculations
              //
              /////////////////////////////////////////////////////////////////
              setconsumerTreatmentGrowthCost(growthCost / MATH.weeklyAnnual);
              setconsumerPracticeGrowthRevenue(growthRevenue / MATH.weeklyAnnual);
              setconsumerTreatmentGrowthProfit(growthProfit / MATH.weeklyAnnual);

              // Profit Different By Week
              let values = getProfits(currentGrowthTier);
              //console.log('this is my Weekly current tier ----> ', currentTier);
              const clinicProfit = values.profit / MATH.weeksPerMonth - regualrProfit;
              //console.log('this is my clinic profit --->', clinicProfit);
              growthDifference = { growthDifference: clinicProfit, breakdown: 'Weekly' };
              setconsumerAnnualTreatmenGrowthtProfit(growthProfit);
              setconsumerAnnualTreatmentGrowthCost(growthCost);
              setconsumerAnnualPracticeGrowthRevenue(growthRevenue);

              break;
            case TIMERANGE.monthly:
              //Tips are being calculated by months
              //Chart will show 12 Months * 4
              /* for (let i = 0; i < MATH.monthlyAnnual * monthBreakdown; i++) {
                chartData[i][nextTier.tierName] = nextTierprofit * (i + 1);
              } */

              //Figure out if they upgrade in six months
              for (let i = 0; i < MATH.monthlyAnnual / 2; i++) {
                if (totalGrowthSpend <= nextTierSpendGrowthMin) {
                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend;
                  growthProfit = growthProfit + values.profit;
                  growthRevenue = growthRevenue + values.revenue;
                  growthCost = growthCost + values.cost;

                  chartData[i][growthProfits] = growthProfit;
                  chartData[i].growthTier = currentGrowthTier.tierName;
                  chartData[i].growthTipCost = values.cost;
                  chartData[i].growthTotalSpend = totalGrowthSpend;
                } else {
                  currentGrowthTier = nextPossibleGrowthTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentGrowthTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleGrowthTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendGrowthMin = nextPossibleGrowthTier.min;

                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend;
                  growthProfit = growthProfit + values.profit;
                  growthRevenue = growthRevenue + values.revenue;
                  growthCost = growthCost + values.cost;

                  chartData[i][growthProfits] = growthProfit;
                  chartData[i].growthTier = currentGrowthTier.tierName;
                  chartData[i].growthTipCost = values.cost;
                  chartData[i].growthTotalSpend = totalGrowthSpend;
                }
              }
              //Now we will go through the rest of the months
              totalGrowthSpend = 0;
              for (let i = MATH.monthlyAnnual / 2; i < MATH.monthlyAnnual * monthBreakdown; i++) {
                let values = getProfits(currentGrowthTier);

                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////

                totalGrowthSpend = totalGrowthSpend + values.spend;
                growthProfit = growthProfit + values.profit;
                growthRevenue = growthRevenue + values.revenue;
                growthCost = growthCost + values.cost;

                chartData[i][growthProfits] = growthProfit;
                chartData[i].growthTier = currentGrowthTier.tierName;
                chartData[i].growthTipCost = values.cost;
                chartData[i].growthTotalSpend = totalGrowthSpend;
              }

              /////////////////////////////////////////////////////////////////
              //
              // Post the annual calculations
              //
              /////////////////////////////////////////////////////////////////
              setconsumerTreatmentGrowthCost(growthCost / monthBreakdown / MATH.monthlyAnnual);
              setconsumerPracticeGrowthRevenue(growthRevenue / monthBreakdown / MATH.monthlyAnnual);
              setconsumerTreatmentGrowthProfit(growthProfit / monthBreakdown / MATH.monthlyAnnual);

              // Profit Different By Week
              //console.log('this is my Monthlycurrent tier ----> ', currentTier);
              let monthValues = getProfits(currentGrowthTier);
              const clinicMonthProfit = monthValues.profit - regualrProfit;
              growthDifference = { growthDifference: clinicMonthProfit, breakdown: 'Monthly' };
              setconsumerAnnualTreatmenGrowthtProfit(growthProfit / monthBreakdown);
              setconsumerAnnualTreatmentGrowthCost(growthCost / monthBreakdown);
              setconsumerAnnualPracticeGrowthRevenue(growthRevenue / monthBreakdown);

              break;
            case TIMERANGE.quarterly:
              //Tips are being calculated by months * 3
              //Chart will show 4 quarters * 12
              /* for (let i = 0; i < MATH.quarterlyAnnual * quarterBreakdown; i++) {
                chartData[i][nextTier.tierName] = nextTierprofit * MATH.monthlyQuarterly * (i + 1);
              } */

              //Figure out if they upgrade in six months
              for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
                if (totalGrowthSpend <= nextTierSpendGrowthMin) {
                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend / 4;
                } else {
                  currentGrowthTier = nextPossibleGrowthTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentGrowthTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleGrowthTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendGrowthMin = nextPossibleGrowthTier.min;

                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend / 4;
                }
              }
              totalGrowthSpend = 0;
              for (let i = 0; i < MATH.quarterlyAnnual * quarterBreakdown; i++) {
                //chartData[i][nextTier.tierName] = nextTierprofit * MATH.weeklyQuarterly * (i + 1);
                let values = getProfits(currentGrowthTier);

                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////

                totalGrowthSpend = totalGrowthSpend + values.spend * MATH.monthlyQuarterly;
                growthProfit = growthProfit + values.profit * MATH.monthlyQuarterly;
                growthRevenue = growthRevenue + values.revenue * MATH.monthlyQuarterly;
                growthCost = growthCost + values.cost * MATH.monthlyQuarterly;

                chartData[i][growthProfits] = growthProfit;
                chartData[i].growthTier = currentGrowthTier.tierName;
                chartData[i].growthTipCost = values.cost * MATH.monthlyQuarterly;
                chartData[i].growthTotalSpend = totalGrowthSpend;
              }
              // Breakdown by quarters
              /////////////////////////////////////////////////////////////////
              //
              // Post the annual calculations
              //
              /////////////////////////////////////////////////////////////////

              setconsumerTreatmentGrowthCost(growthCost / quarterBreakdown / MATH.quarterlyAnnual);
              setconsumerPracticeGrowthRevenue(growthRevenue / quarterBreakdown / MATH.quarterlyAnnual);
              setconsumerTreatmentGrowthProfit(growthProfit / quarterBreakdown / MATH.quarterlyAnnual);

              // Profit Different By Week
              let quarterValues = getProfits(currentGrowthTier);
              //console.log('this is my current Quartly tier ----> ', currentTier);
              const clinicQuarterProfit = quarterValues.profit * MATH.monthlyQuarterly - regualrProfit;
              growthDifference = { growthDifference: clinicQuarterProfit, breakdown: 'Quartly' };
              setconsumerAnnualTreatmenGrowthtProfit(growthProfit / quarterBreakdown);
              setconsumerAnnualTreatmentGrowthCost(growthCost / quarterBreakdown);
              setconsumerAnnualPracticeGrowthRevenue(growthRevenue / quarterBreakdown);

              break;
            case TIMERANGE.annual:
              //Tips are being calculated by months * 12
              // Chart will show years * 12
              /* for (let i = 0; i < MATH.annualy * yearBreakdown; i++) {
                chartData[i][nextTier.tierName] = nextTierprofit * MATH.monthlyAnnual * (i + 1);
              } */
              //We will see if they upgrade in 6 months

              for (let i = 0; i < MATH.monthlyAnnual / 2; i++) {
                if (totalGrowthSpend <= nextTierSpendGrowthMin) {
                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend;
                } else {
                  currentGrowthTier = nextPossibleGrowthTier;
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentGrowthTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleGrowthTier = tiers[foundTier + 1];
                  }
                  //Set the new minumams
                  //currentTierSpendMin = currentTier.min;
                  nextTierSpendGrowthMin = nextPossibleGrowthTier.min;

                  let values = getProfits(currentGrowthTier);

                  totalGrowthSpend = totalGrowthSpend + values.spend;
                }
              }
              totalGrowthSpend = 0;
              for (let i = 0; i < MATH.annualy * yearBreakdown; i++) {
                //chartData[i][nextTier.tierName] = nextTierprofit * MATH.weeklyAnnual * (i + 1);
                let values = getProfits(currentGrowthTier);

                //////////////////////////////////////////////////////
                //
                // Now that we are in the final six months we will use this data to
                // Set the different values
                //
                //////////////////////////////////////////////////////

                totalGrowthSpend = totalGrowthSpend + values.spend * MATH.monthlyAnnual;
                growthProfit = growthProfit + values.profit * MATH.monthlyAnnual;
                growthRevenue = growthRevenue + values.revenue * MATH.monthlyAnnual;
                growthCost = growthCost + values.cost * MATH.monthlyAnnual;

                chartData[i][growthProfits] = growthProfit;
                chartData[i].growthTier = currentGrowthTier.tierName;
                chartData[i].growthTipCost = values.cost * MATH.monthlyAnnual;
                chartData[i].growthTotalSpend = totalGrowthSpend;
              }

              setconsumerTreatmentGrowthCost(growthCost / yearBreakdown / MATH.annualy);
              setconsumerPracticeGrowthRevenue(growthRevenue / yearBreakdown / MATH.annualy);
              setconsumerTreatmentGrowthProfit(growthProfit / yearBreakdown / MATH.annualy);

              // Profit Different By Week
              let annualValues = getProfits(currentGrowthTier);
              //console.log('this is my ANNUAL current tier ----> ', currentTier);
              const clinicAnnualProfit = annualValues.profit * MATH.monthlyAnnual - regualrProfit;
              growthDifference = { growthDifference: clinicAnnualProfit, breakdown: 'Yearly' };
              setconsumerAnnualTreatmenGrowthtProfit(growthProfit / yearBreakdown);
              setconsumerAnnualTreatmentGrowthCost(growthCost / yearBreakdown);
              setconsumerAnnualPracticeGrowthRevenue(growthRevenue / yearBreakdown);

              break;
            default:
              chartData = [];
          }
        }

        //console.log('this is my grwoth difference before I set it 0000', growthDifference);

        setConsumerDifference({ ...growthDifference });

        setChartXData([...xAxis]);

        setChartData([...chartData]);
        setPDfCapitalDataReady(true);
      }
    }
    return () => {};
  }, [selectedTier, tiers, selectedTips, roiTipCount, tiersLoading, systemCosts, selectedTimeBreakdown, selectedTimeRange, clinicTaxBraket, monthlyPayment, roiMode, growthTips, currentSpend]);

  /////////////////////////////////////////////
  //
  // Consumer Calculations
  // >> This function will calculate all of the
  //    consumer calcultions and growth
  //
  /////////////////////////////////////////////

  useEffect(() => {
    if (roiMode === MODE.consumer && selectedTier && !tiersLoading) {
      const clinicSpend = (currentTier) => {
        const tipCosts = selectedTips.map((tip) => (tip[currentTier.id] ? (tip[currentTier.id] / tip.tipsPerPack) * tip.amount : 0));
        const tipTotal = tipCosts.reduce((sum, price) => sum + price, 0);

        const tipGrowthCosts = growthTips.map((tip) => (tip[currentTier.id] ? (tip[currentTier.id] / tip.tipsPerPack) * tip.amount : 0));
        const tipGrothTotal = tipGrowthCosts.reduce((sum, price) => sum + price, 0);
        return { tipTotal, tipGrothTotal };
      };

      //We will need to figure out what the current teir floor range is
      let currentTier = tiers.find((t) => t.id === selectedTier);
      //console.log('this is the current tier -----> ', currentTier);
      //console.log('this is my current spend ', currentSpend, growthTips, selectedTier, nextTier);

      let totalSpend = currentSpend ?? 0;
      //console.log('this is my total spend ----> ', totalSpend);
      let xAxis = [];
      let chartData = [];
      //Next we need to figure out the tip calculations
      //is it WEEKLY or MONTHLY
      let nextPossibleTier = nextTier;

      const today = new Date();

      const currentYear = today.getFullYear();
      const firstSixMonthsEndDate = new Date(currentYear, 5, 30); // June is month 5 (0-indexed)
      const secondSixMonthsEndDate = new Date(currentYear, 11, 31); // December is month 11 (0-indexed)

      let endOfYear;
      if (today <= firstSixMonthsEndDate) {
        endOfYear = firstSixMonthsEndDate;
      } else if (today <= secondSixMonthsEndDate) {
        endOfYear = secondSixMonthsEndDate;
      } else {
        // If current date is after December 31st, use next year's end date
        endOfYear = new Date(currentYear + 1, 5, 30);
      }

      let nextTierSpendMin = nextPossibleTier.min;

      if (selectedTimeRange === TIMERANGE.weekly) {
        switch (selectedTimeBreakdown) {
          case TIMERANGE.weekly:
            /////////////////////////////////////////////////
            //
            // We will need to figure out how many weeks are
            // Left in the week range of
            //
            /////////////////////////////////////////////////

            const daysLeft = Math.floor((endOfYear - today) / (1000 * 60 * 60 * 24)); // Milliseconds in a day
            let weeksLeft = Math.ceil(daysLeft / 7);

            //We are only using a 48 week calendar
            if (weeksLeft > 24) {
              weeksLeft = 24;
            }
            //console.log('this is my weeks left > ', weeksLeft);
            //We will need to figure out the first XAxis
            xAxis.push({ key: currentTier.tierName });

            //We will need to figure out things in 6 month intervals
            for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
              //console.log('this is my total spend , next tier spend min and current tier', totalSpend, nextTierSpendMin, currentTier);

              if (totalSpend <= nextTierSpendMin || currentTier.tierName === 'Diamond') {
                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;

                //console.log('this is my total spend and my min spend ---->', totalSpend, nextTierSpendMin);

                /* if (totalSpend >= nextTierSpendMin) {
                  currentTier = nextPossibleTier;
                  xAxis.push({ key: currentTier.tierName });
                  //console.log('this is my current tier ----> ', currentTier.tierName);
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  nextTierSpendMin = nextPossibleTier.min;
                } */
                //console.log('this is my current tier name I am adding to the x chart  ----> ', currentTier.tierName);
                chartData.push({ [currentTier.tierName]: totalSpend, name: `Week ${i + 1}` });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //console.log('this is my spend on week ---->', i, totalSpend);
              } else {
                //console.log('this is ny mnext posible tier??? ');
                currentTier = nextPossibleTier;
                xAxis.push({ key: currentTier.tierName });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }

                //Set the new minumams
                //currentTierSpendMin = currentTier.min;
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;

                //console.log('this is my current tier name I am adding to the x chart ELSE STATEMENT  ----> ', currentTier.tierName);
                //console.log('this is my chart data???? ', chartData);
                chartData.push({ [currentTier.tierName]: totalSpend, name: `Week ${i + 1}` });
              }
            }

            ///////////////////////////////////////////////
            //
            // Next 6 Monhts
            //
            ///////////////////////////////////////////////
            totalSpend = 0;

            for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
              if (totalSpend <= nextTierSpendMin || currentTier.tierName === 'Diamond') {
                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;

                //Check to see if we make it to the next round
                /* if (totalSpend >= nextTierSpendMin) {
                  currentTier = nextPossibleTier;
                  xAxis.push({ key: currentTier.tierName });
                  //console.log('this is my current tier ----> ', currentTier.tierName);
                  //We will need to figure out the next tier
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  nextTierSpendMin = nextPossibleTier.min;
                } */

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Week ${i + 1}` });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //console.log('this is my spend on week ---->', i, totalSpend);
              } else {
                currentTier = nextPossibleTier;
                xAxis.push({ key: currentTier.tierName });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }

                //Set the new minumams
                //currentTierSpendMin = currentTier.min;
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Week ${i + 1}` });
              }
            }

            setConsumerChartData([...chartData]);
            setConsumerChartXData([...xAxis]);
            break;
          case TIMERANGE.monthly:
            /////////////////////////////////////////////////
            //
            // We will need to figure out how many months are
            // Left in the week range of
            //
            /////////////////////////////////////////////////
            let endOfPeriod;
            if (today <= firstSixMonthsEndDate) {
              endOfPeriod = firstSixMonthsEndDate;
            } else if (today <= secondSixMonthsEndDate) {
              endOfPeriod = secondSixMonthsEndDate;
            } else {
              // If current date is after December 31st, use next year's first end date
              endOfPeriod = new Date(currentYear + 1, 5, 30);
            }

            // Calculate the difference in months between today and the end of the current six-month period
            const monthsLeftInPeriod = (endOfPeriod.getFullYear() - today.getFullYear()) * 12 + endOfPeriod.getMonth() - today.getMonth() + 1;

            //We will need to figure out the first XAxis
            xAxis.push({ key: currentTier.tierName });

            //We will need to figure out things in 6 month intervals
            for (let i = 0; i < MATH.monthlyAnnual / 2; i++) {
              if (totalSpend <= nextTierSpendMin || currentTier.tierName === 'Diamond') {
                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal * MATH.weeklyMonthly + spend.tipGrothTotal * MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;

                /* if (totalSpend >= nextTierSpendMin) {
                  currentTier = nextPossibleTier;
                  xAxis.push({ key: currentTier.tierName });
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  nextTierSpendMin = nextPossibleTier.min;
                } */

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Month ${i + 1}` });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //console.log('this is my spend on week ---->', i, totalSpend);
              } else {
                currentTier = nextPossibleTier;
                xAxis.push({ key: currentTier.tierName });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }

                //Set the new minumams
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal * MATH.weeklyMonthly + spend.tipGrothTotal * MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;
                chartData.push({ [currentTier.tierName]: totalSpend, name: `Month ${i + 1}` });
              }
            }

            ///////////////////////////////////////////////
            //
            // Next 6 Monhts
            //
            ///////////////////////////////////////////////
            totalSpend = 0;

            for (let i = 0; i < MATH.monthlyAnnual / 2; i++) {
              if (totalSpend <= nextTierSpendMin || currentTier.tierName === 'Diamond') {
                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal * MATH.weeklyMonthly + spend.tipGrothTotal * MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;

                if (totalSpend >= nextTierSpendMin) {
                  currentTier = nextPossibleTier;
                  xAxis.push({ key: currentTier.tierName });
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  nextTierSpendMin = nextPossibleTier.min;
                }

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Month ${i + 1}` });
              } else {
                currentTier = nextPossibleTier;
                xAxis.push({ key: currentTier.tierName });
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }

                //Set the new minumams
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal * MATH.weeklyMonthly + spend.tipGrothTotal * MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Week ${i + 1}` });
              }
            }

            break;
          case TIMERANGE.quarterly:
            /////////////////////////////////////////////////
            //
            // We will need to figure out how many months are
            // Left in the week range of
            //
            /////////////////////////////////////////////////

            const firstQuarterEndDate = new Date(currentYear, 2, 31); // March is month 2 (0-indexed)
            const secondQuarterEndDate = new Date(currentYear, 5, 30); // June is month 5 (0-indexed)
            const thirdQuarterEndDate = new Date(currentYear, 8, 30); // September is month 8 (0-indexed)
            //const fourthQuarterEndDate = new Date(currentYear, 11, 31); // December is month 11 (0-indexed)

            let endOfQuarter;
            if (today <= firstQuarterEndDate) {
              endOfQuarter = firstQuarterEndDate;
            } else if (today <= secondQuarterEndDate) {
              endOfQuarter = secondQuarterEndDate;
            } else if (today <= thirdQuarterEndDate) {
              endOfQuarter = thirdQuarterEndDate;
            } else {
              // If current date is in the fourth quarter or beyond, use next year's first quarter end date
              endOfQuarter = new Date(currentYear + 1, 2, 31);
            }

            // Calculate the difference in quarters between today and the end of the current quarter, including the current quarter
            const quartersLeft = Math.ceil((endOfQuarter.getFullYear() - today.getFullYear()) * 4 + (endOfQuarter.getMonth() - today.getMonth()) / 3) + 1;

            //We will need to figure out the first XAxis
            //xAxis.push({ key: currentTier.tierName });

            //We will need to figure out things in first quarter month intervals
            for (let i = 0; i < MATH.weeklyAnnual / 2 / 2; i++) {
              if (totalSpend <= nextTierSpendMin) {
                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);

                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;
              } else {
                currentTier = nextPossibleTier;
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }
                //Set the new minumams
                //currentTierSpendMin = currentTier.min;
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;

                totalSpend = totalSpend + mySpend;
              }
            }

            //console.log('this is my total spend that I am pushing into the chart data.... ');
            //console.log('this is my current tier ----?>', currentTier.tierName);
            //console.log('this is my toal spend ---->', totalSpend);

            xAxis.push({ key: currentTier.tierName });
            chartData.push({ [currentTier.tierName]: totalSpend, name: `Quarter 1` });

            //We will need to figure out things in the second quarter month intervals
            for (let i = 0; i < MATH.weeklyAnnual / 2 / 2; i++) {
              if (totalSpend <= nextTierSpendMin) {
                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);

                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;
              } else {
                currentTier = nextPossibleTier;
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }
                //Set the new minumams
                //currentTierSpendMin = currentTier.min;
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;

                totalSpend = totalSpend + mySpend;
              }
            }

            xAxis.push({ key: currentTier.tierName });

            chartData.push({ [currentTier.tierName]: totalSpend, name: `Quarter 2` });

            ///////////////////////////////////////////////
            //
            // Next 6 Monhts
            //
            ///////////////////////////////////////////////
            totalSpend = 0;

            for (let i = 0; i < MATH.quarterlyAnnual / 2; i++) {
              let spend = clinicSpend(currentTier);
              const mySpend = spend.tipTotal * MATH.weeklyQuarterly + spend.tipGrothTotal * MATH.weeklyQuarterly;
              totalSpend = totalSpend + mySpend;

              chartData.push({ [currentTier.tierName]: totalSpend, name: `Quarter ${i + 1}` });
            }

            break;
          case TIMERANGE.annual:
            for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
              if (totalSpend <= nextTierSpendMin) {
                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);

                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;
              } else {
                currentTier = nextPossibleTier;
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }
                //Set the new minumams
                //currentTierSpendMin = currentTier.min;
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;

                totalSpend = totalSpend + mySpend;
              }
            }

            //xAxis.push({ key: currentTier.tierName });

            chartData.push({ [currentTier.tierName]: totalSpend, name: `Half 1` });

            totalSpend = 0;
            for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
              let spend = clinicSpend(currentTier);

              //console.log('this is my clinics spend in the first six months >', spend);
              const mySpend = spend.tipTotal + spend.tipGrothTotal;

              totalSpend = totalSpend + mySpend;
            }

            xAxis.push({ key: currentTier.tierName });

            chartData.push({ [currentTier.tierName]: totalSpend, name: `Half 2` });

            break;
          default:
            chartData = [];
        }

        setConsumerChartData([...chartData]);
        setConsumerChartXData([...xAxis]);
      } else if (selectedTimeRange === TIMERANGE.monthly) {
        switch (selectedTimeBreakdown) {
          case TIMERANGE.weekly:
            /////////////////////////////////////////////////
            //
            // We will need to figure out how many weeks are
            // Left in the week range of
            //
            /////////////////////////////////////////////////

            const daysLeft = Math.floor((endOfYear - today) / (1000 * 60 * 60 * 24)); // Milliseconds in a day
            const weeksLeft = Math.ceil(daysLeft / 7);

            //We will need to figure out the first XAxis
            xAxis.push({ key: currentTier.tierName });

            //We will need to figure out things in 6 month intervals
            for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
              if (totalSpend <= nextTierSpendMin) {
                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal / MATH.weeklyMonthly + spend.tipGrothTotal / MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Week ${i + 1}` });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //console.log('this is my spend on week ---->', i, totalSpend);
              } else {
                currentTier = nextPossibleTier;
                xAxis.push({ key: currentTier.tierName });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }

                //Set the new minumams
                //currentTierSpendMin = currentTier.min;
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal / MATH.weeklyMonthly + spend.tipGrothTotal / MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Week ${i + 1}` });
              }
            }

            ///////////////////////////////////////////////
            //
            // Next 6 Monhts
            //
            ///////////////////////////////////////////////
            totalSpend = 0;

            for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
              if (totalSpend <= nextTierSpendMin) {
                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal / MATH.weeklyMonthly + spend.tipGrothTotal / MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Week ${i + 1}` });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //console.log('this is my spend on week ---->', i, totalSpend);
              } else {
                currentTier = nextPossibleTier;
                xAxis.push({ key: currentTier.tierName });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }

                //Set the new minumams
                //currentTierSpendMin = currentTier.min;
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal / MATH.weeklyMonthly + spend.tipGrothTotal / MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Week ${i + 1}` });
              }
            }

            setConsumerChartData([...chartData]);
            setConsumerChartXData([...xAxis]);
            break;
          case TIMERANGE.monthly:
            /////////////////////////////////////////////////
            //
            // We will need to figure out how many months are
            // Left in the week range of
            //
            /////////////////////////////////////////////////
            let endOfPeriod;
            if (today <= firstSixMonthsEndDate) {
              endOfPeriod = firstSixMonthsEndDate;
            } else if (today <= secondSixMonthsEndDate) {
              endOfPeriod = secondSixMonthsEndDate;
            } else {
              // If current date is after December 31st, use next year's first end date
              endOfPeriod = new Date(currentYear + 1, 5, 30);
            }

            // Calculate the difference in months between today and the end of the current six-month period
            const monthsLeftInPeriod = (endOfPeriod.getFullYear() - today.getFullYear()) * 12 + endOfPeriod.getMonth() - today.getMonth() + 1;

            //We will need to figure out the first XAxis
            xAxis.push({ key: currentTier.tierName });

            //We will need to figure out things in 6 month intervals
            for (let i = 0; i < MATH.monthlyAnnual / 2; i++) {
              if (totalSpend <= nextTierSpendMin) {
                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;

                if (totalSpend >= nextTierSpendMin) {
                  currentTier = nextPossibleTier;
                  xAxis.push({ key: currentTier.tierName });
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  nextTierSpendMin = nextPossibleTier.min;
                }

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Month ${i + 1}` });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //console.log('this is my spend on week ---->', i, totalSpend);
              } else {
                currentTier = nextPossibleTier;
                xAxis.push({ key: currentTier.tierName });
                //console.log('this is my current tier ----> ', currentTier.tierName);
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }

                //Set the new minumams
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;
                chartData.push({ [currentTier.tierName]: totalSpend, name: `Month ${i + 1}` });
              }
            }

            ///////////////////////////////////////////////
            //
            // Next 6 Monhts
            //
            ///////////////////////////////////////////////
            totalSpend = 0;

            for (let i = 0; i < MATH.monthlyAnnual / 2; i++) {
              if (totalSpend <= nextTierSpendMin) {
                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;

                if (totalSpend >= nextTierSpendMin) {
                  currentTier = nextPossibleTier;
                  xAxis.push({ key: currentTier.tierName });
                  const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                  if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                    nextPossibleTier = tiers[foundTier + 1];
                  }
                  nextTierSpendMin = nextPossibleTier.min;
                }

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Month ${i + 1}` });
              } else {
                currentTier = nextPossibleTier;
                xAxis.push({ key: currentTier.tierName });
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }

                //Set the new minumams
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);
                const mySpend = spend.tipTotal + spend.tipGrothTotal;
                totalSpend = totalSpend + mySpend;

                chartData.push({ [currentTier.tierName]: totalSpend, name: `Week ${i + 1}` });
              }
            }

            break;
          case TIMERANGE.quarterly:
            /////////////////////////////////////////////////
            //
            // We will need to figure out how many months are
            // Left in the week range of
            //
            /////////////////////////////////////////////////

            const firstQuarterEndDate = new Date(currentYear, 2, 31); // March is month 2 (0-indexed)
            const secondQuarterEndDate = new Date(currentYear, 5, 30); // June is month 5 (0-indexed)
            const thirdQuarterEndDate = new Date(currentYear, 8, 30); // September is month 8 (0-indexed)
            //const fourthQuarterEndDate = new Date(currentYear, 11, 31); // December is month 11 (0-indexed)

            let endOfQuarter;
            if (today <= firstQuarterEndDate) {
              endOfQuarter = firstQuarterEndDate;
            } else if (today <= secondQuarterEndDate) {
              endOfQuarter = secondQuarterEndDate;
            } else if (today <= thirdQuarterEndDate) {
              endOfQuarter = thirdQuarterEndDate;
            } else {
              // If current date is in the fourth quarter or beyond, use next year's first quarter end date
              endOfQuarter = new Date(currentYear + 1, 2, 31);
            }

            // Calculate the difference in quarters between today and the end of the current quarter, including the current quarter
            const quartersLeft = Math.ceil((endOfQuarter.getFullYear() - today.getFullYear()) * 4 + (endOfQuarter.getMonth() - today.getMonth()) / 3) + 1;

            //We will need to figure out the first XAxis
            //xAxis.push({ key: currentTier.tierName });

            //We will need to figure out things in first quarter month intervals
            for (let i = 0; i < MATH.weeklyAnnual / 2 / 2; i++) {
              if (totalSpend <= nextTierSpendMin) {
                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);

                const mySpend = spend.tipTotal / MATH.weeklyMonthly + spend.tipGrothTotal / MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;
              } else {
                currentTier = nextPossibleTier;
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }
                //Set the new minumams
                //currentTierSpendMin = currentTier.min;
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);
                const mySpend = spend.tipTotal / MATH.weeklyMonthly + spend.tipGrothTotal / MATH.weeklyMonthly;

                totalSpend = totalSpend + mySpend;
              }
            }

            xAxis.push({ key: currentTier.tierName });
            chartData.push({ [currentTier.tierName]: totalSpend, name: `Quarter 1` });

            //We will need to figure out things in the second quarter month intervals
            for (let i = 0; i < MATH.weeklyAnnual / 2 / 2; i++) {
              if (totalSpend <= nextTierSpendMin) {
                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);

                const mySpend = spend.tipTotal / MATH.weeklyMonthly + spend.tipGrothTotal / MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;
              } else {
                currentTier = nextPossibleTier;
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }
                //Set the new minumams
                //currentTierSpendMin = currentTier.min;
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);

                xAxis.push({ key: currentTier.tierName });

                //console.log('this is my clinics spend in the first six months >', spend);
                const mySpend = spend.tipTotal / MATH.weeklyMonthly + spend.tipGrothTotal / MATH.weeklyMonthly;

                totalSpend = totalSpend + mySpend;
              }
            }

            chartData.push({ [currentTier.tierName]: totalSpend, name: `Quarter 2` });

            ///////////////////////////////////////////////
            //
            // Next 6 Monhts
            //
            ///////////////////////////////////////////////
            totalSpend = 0;

            for (let i = 0; i < MATH.quarterlyAnnual / 2; i++) {
              let spend = clinicSpend(currentTier);
              const mySpend = spend.tipTotal * MATH.monthlyQuarterly + spend.tipGrothTotal * MATH.monthlyQuarterly;
              totalSpend = totalSpend + mySpend;

              chartData.push({ [currentTier.tierName]: totalSpend, name: `Quarter ${i + 1}` });
            }

            break;
          case TIMERANGE.annual:
            for (let i = 0; i < MATH.weeklyAnnual / 2; i++) {
              if (totalSpend <= nextTierSpendMin) {
                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);

                const mySpend = spend.tipTotal / MATH.weeklyMonthly + spend.tipGrothTotal / MATH.weeklyMonthly;
                totalSpend = totalSpend + mySpend;
              } else {
                currentTier = nextPossibleTier;
                //We will need to figure out the next tier
                const foundTier = tiers.findIndex((tier) => tier.id === currentTier.id);
                if (foundTier !== -1 && foundTier !== tiers.length - 1) {
                  nextPossibleTier = tiers[foundTier + 1];
                }
                //Set the new minumams
                //currentTierSpendMin = currentTier.min;
                nextTierSpendMin = nextPossibleTier.min;

                let spend = clinicSpend(currentTier);

                //console.log('this is my clinics spend in the first six months >', spend);
                const mySpend = spend.tipTotal / MATH.weeklyMonthly + spend.tipGrothTotal / MATH.weeklyMonthly;

                totalSpend = totalSpend + mySpend;
              }
            }

            xAxis.push({ key: currentTier.tierName });

            chartData.push({ [currentTier.tierName]: totalSpend, name: `Half 1` });

            //Reset the spend for the last month

            totalSpend = 0;

            let spend = clinicSpend(currentTier);

            //console.log('this is my clinics spend in the first six months >', spend);
            const mySpend = spend.tipTotal * (MATH.monthlyAnnual / 2) + spend.tipGrothTotal * (MATH.monthlyAnnual / 2);

            totalSpend = totalSpend + mySpend;

            //xAxis.push({ key: currentTier.tierName });

            chartData.push({ [currentTier.tierName]: totalSpend, name: `Half 2` });

            break;
          default:
            chartData = [];
        }

        setConsumerChartData([...chartData]);
        setConsumerChartXData([...xAxis]);
        setPDfConsumerDataReady(true);
      }
    }

    return () => {};
  }, [growthTips, selectedTips, roiMode, currentSpend, selectedTier, nextTier, selectedTimeRange, tiers, selectedTimeBreakdown]);

  /////////////////////////////////////////////
  //
  // Set ROI
  // >> This function will standerize our ROI data
  //
  /////////////////////////////////////////////
  const setROIFromFirestore = ({ id, data }) => {
    const roi = data.data();

    const systems = roi.systems ?? '';

    const cleanROI = {
      id: id,
      added: formatDate(roi.added),
      rep: roi.rep,
      clinicName: roi.clinicName ?? '',
      reportName: roi.reportName ?? '',
      folder: roi.folder ?? '',
      systems: {
        ...systems,
      },
    };

    //Get all of the ids of the systems
    const systemArray = Object.values(cleanROI.systems).map((system) => system);

    //console.log('this is my systemArray ---> ', systemArray);
    //This will load all of the tips
    setSystemsSelected([...systemArray]);

    //Load our tips into the system
    setSelectedTips(roi.tips && roi.tips.length > 0 ? [...roi.tips] : defaultTips);
    setClinicTaxBraket(roi.taxBraket ?? 0);
    setAddFinancing(roi.financing ?? false);
    setinterestRate(roi.interstRate ?? 0);
    setMonthlyPayment(roi.monthlyPayment ?? 0);
    setSelectedTier(roi.tier ?? '');
    setSelectedTimeRange(roi.selectedTimeRange ?? TIMERANGE.weekly);
    setROIMode(roi.mode ? roi.mode : MODE.capital);
    setCurrentSpend(roi.currentSpend ? roi.currentSpend : 0);
    setGrowthTips(roi.growthTips && roi.growthTips.length > 0 ? [...roi.growthTips] : defaultGrowthTips);

    return setROI({ ...cleanROI });
  };

  /////////////////////////////////////////////
  //
  // Create New ROI
  // >> This function will create a new roi
  // >>> Then send the user to the ROI Dashboard
  //
  /////////////////////////////////////////////
  const createNewROI = async () => {
    resetROI();

    let roi = {
      rep: user.id,
      added: new Date(),
    };

    if (user) {
      roi.rep = user.id;
      let newROI = await addDoc(collection(firestore, 'roi'), { ...roi });

      setROI({ ...roi, id: newROI.id });
      setROIID(newROI.id);
      setROILoading(false);
    }
  };

  /////////////////////////////////////////////
  //
  // Load ROI
  // >> This function will load a reps ROI
  //
  /////////////////////////////////////////////
  const loadROI = async (id, roi) => {
    resetROI();
    setPDFROI(roi);
    const loadROI = await getDoc(doc(firestore, 'roi', id));

    if (loadROI.exists()) {
      setROIFromFirestore({ id: id, data: loadROI });
      setROIID(id);
      setROILoading(false);
    } else {
      setROIError('There is no ROI with that ID');
      setRoiAlert(true);
    }
  };

  /////////////////////////////////////////////
  //
  // Add Systems
  // >> This function will add a system to the roi
  //
  /////////////////////////////////////////////
  const addSystem = (id) => {
    let system = systems.find((sys) => sys.id === id);
    //console.log('this is my system ---> ', system);
    if (system) {
      let myROI = roi;
      if (roi.systems) {
        myROI.systems[id] = { ...system };
      } else {
        myROI.systems = {};
        myROI.systems[id] = { ...system };
      }

      //console.group('this is my roi before I update it ---->', myROI);
      setROI({ ...myROI });
      systemAddedToROI(id);

      //Need to get the tips once we have added in the system
    }
  };

  /////////////////////////////////////////////
  //
  // Change Systems Price
  // >> This function will change the systems price for the roi
  //
  /////////////////////////////////////////////
  const changeSystemPrice = (id, price) => {
    let myROI = roi;
    myROI.systems[id].price = convertToNumber(price);
    setROI({ ...myROI });
  };

  /////////////////////////////////////////////
  //
  // Change Clinics Name
  // >> This function will change the clinics name
  //
  /////////////////////////////////////////////
  const changeClinicName = (name) => {
    let myROI = roi;
    myROI.clinicName = name;
    setROI({ ...myROI });
  };

  /////////////////////////////////////////////
  //
  // Change Reports Name
  // >> This function will change the reports name
  //
  /////////////////////////////////////////////
  const changeReportsName = (name) => {
    let myROI = roi;
    myROI.reportName = name;
    setROI({ ...myROI });
  };

  /////////////////////////////////////////////
  //
  // Add Another Treatment
  // >> This function will add a blank treatment
  //
  /////////////////////////////////////////////
  const addAnotherTreatment = () => {
    let mySelectedTips = selectedTips.map((tip) => ({ ...tip }));
    mySelectedTips.push({ id: '' });
    setSelectedTips([...mySelectedTips]);
  };

  /////////////////////////////////////////////
  //
  // Remove Treatment
  // >> This function will remove a treatment
  //
  /////////////////////////////////////////////
  const removeTreatment = (index) => {
    let mySelectedTips = selectedTips.map((tip) => ({ ...tip }));
    mySelectedTips.splice(index, 1);
    setSelectedTips([...mySelectedTips]);
  };

  /////////////////////////////////////////////
  //
  // Add Another Treatment
  // >> This function will add a blank treatment
  //
  /////////////////////////////////////////////
  const addAnotherTreatmentGrowth = () => {
    let myGrowthTips = growthTips.map((tip) => ({ ...tip }));
    myGrowthTips.push({ id: '', type: 'growth' });
    //console.log('this is my tips ----> ', myGrowthTips);
    setGrowthTips([...myGrowthTips]);
  };

  /////////////////////////////////////////////
  //
  // Remove Treatment
  // >> This function will remove a treatment
  //
  /////////////////////////////////////////////
  const removeTreatmentGrowth = (index) => {
    let mySelectedTips = growthTips.map((tip) => ({ ...tip }));
    mySelectedTips.splice(index, 1);
    setGrowthTips([...mySelectedTips]);
  };
  /////////////////////////////////////////////
  //
  // Add Tip Count
  // >> This function will add an amount to the seletected tip
  //
  //
  /////////////////////////////////////////////
  const addTipCount = (i) => {
    let mySelectedTips = selectedTips.map((tip) => ({ ...tip }));

    mySelectedTips[i].amount = selectedTips[i].amount + 1;

    return setSelectedTips([...mySelectedTips]);
  };

  const addTipCountGrowth = (i) => {
    //console.log('this is my growth tips ---->', growthTips, i);
    let myGrowthSelectedTips = growthTips.map((tip) => ({ ...tip }));

    myGrowthSelectedTips[i].amount = growthTips[i].amount + 1;

    return setGrowthTips([...myGrowthSelectedTips]);
  };
  /////////////////////////////////////////////
  //
  // Remove Tip Count
  // >> This function will add an amount to the seletected tip
  //
  //
  /////////////////////////////////////////////
  const removeTipCount = (i) => {
    let mySelectedTips = selectedTips.map((tip) => ({ ...tip }));

    mySelectedTips[i].amount = selectedTips[i].amount - 1;

    return setSelectedTips([...mySelectedTips]);
  };

  const removeTipCountGrowth = (i) => {
    let mySelectedTips = growthTips.map((tip) => ({ ...tip }));

    mySelectedTips[i].amount = growthTips[i].amount - 1;

    return setGrowthTips([...mySelectedTips]);
  };

  /////////////////////////////////////////////
  /////////////////////////////////////////////
  /////////////////////////////////////////////
  //
  // CALCULATIONS SECTION
  // >> This is where all of the math will happen
  //
  /////////////////////////////////////////////
  /////////////////////////////////////////////
  /////////////////////////////////////////////

  /////////////////////////////////////////////
  //
  // Consumable Price
  // >> (Item Cost (based on tier) / amount of tips in box) * number of treatments
  //
  /////////////////////////////////////////////
  const consumablePrice = (index) => {
    const myTip = selectedTips[index];
    const consumablePrice = (myTip[selectedTier] / myTip.tipsPerPack) * myTip.amount;

    if (!isNaN(consumablePrice)) {
      return consumablePrice;
    } else {
      return 0;
    }
  };

  /////////////////////////////////////////////
  //
  // Consumable Price
  // >> (Item Cost (based on tier) / amount of tips in box) * number of treatments
  //
  /////////////////////////////////////////////
  const consumablePriceSingle = (index) => {
    const myTip = selectedTips[index];
    const consumablePrice = myTip[selectedTier] / myTip.tipsPerPack;

    if (!isNaN(consumablePrice)) {
      return consumablePrice;
    } else {
      return 0;
    }
  };

  /////////////////////////////////////////////
  //
  // Consumable Price
  // >> (Item Cost (based on tier) / amount of tips in box) * number of treatments
  //
  /////////////////////////////////////////////
  const consumablePriceGrowth = (index) => {
    const myTip = growthTips[index];
    const consumablePrice = myTip[selectedTier] / myTip.tipsPerPack;

    if (!isNaN(consumablePrice)) {
      return consumablePrice;
    } else {
      return 0;
    }
  };

  /////////////////////////////////////////////
  //
  // Tip Price
  // >> (Item Cost (based on tier) / amount of tips in box) * number of treatments
  //
  /////////////////////////////////////////////
  const tipPrice = (index) => {
    const myTip = selectedTips[index];
    const consumablePrice = myTip[selectedTier] / myTip.tipsPerPack;

    if (!isNaN(consumablePrice)) {
      return consumablePrice;
    } else {
      return 0;
    }
  };

  /////////////////////////////////////////////
  //
  // Tip Revenue
  // >> All the tips selected
  // >> amount * treatmentPrice
  //
  /////////////////////////////////////////////
  const tipRevenue = () => {
    //We will need to go through all of the tips
    const mySelectedTips = selectedTips.map((tip) => {
      const treatmentPrice = tip.treatmentPrice ? convertToNumber(tip.treatmentPrice) : 0;

      const amountOfTips = tip.amount ? convertToNumber(tip.amount) : 0;

      //console.log('this is my treatment price ----> ', treatmentPrice, amountOfTips);

      return treatmentPrice * amountOfTips;
    });
    const tipSum = mySelectedTips.reduce((acc, curr) => acc + curr, 0);

    if (!isNaN(tipSum)) {
      return tipSum;
    } else {
      return 0;
    }
  };

  /////////////////////////////////////////////
  //
  // Treatment Costs
  // >> All the tips selected
  // >> tip cost + other + loan payments
  //
  /////////////////////////////////////////////
  const treatmentCosts = () => {
    //We will need to go through all of the tips
    const mySelectedTips = selectedTips.map((tip, i) => {
      const tipCost = tipPrice(i);
      const otherCost = tip.other ? convertToNumber(tip.other) : 0;
      const amountOfTips = tip.amount ? convertToNumber(tip.amount) : 0;
      const officeCost = tipCost + otherCost;

      const totalTreatmentCost = officeCost * amountOfTips;

      return totalTreatmentCost;
    });
    //console.log('this is my myselect trips COST----> ', mySelectedTips);
    const tipSum = mySelectedTips.reduce((a, b) => a + b, 0);

    if (!isNaN(tipSum)) {
      //Next we need to add on the loan payment which is setup to be monthly
      const loanPayment = selectedTimeRange === TIMERANGE.weekly ? convertToNumber(monthlyPayment) / 4 : convertToNumber(monthlyPayment);
      //console.log('this is my tip sum and loan payment ---> ', tipSum, loanPayment);

      return tipSum + loanPayment;
    } else {
      return 0;
    }
  };

  /////////////////////////////////////////////
  //
  // Time Range Practice Revenue
  // >> Time Range value * total revenue
  //
  /////////////////////////////////////////////
  const timeRangePracticeRevenue = () => {
    const totalTipRevenue = tipRevenue();
    //console.log('this is my total tip revenue ---<', totalTipRevenue, selectedTimeBreakdown);

    let timeRangeTipRevenue = 0;
    if (selectedTimeRange === TIMERANGE.weekly) {
      switch (selectedTimeBreakdown) {
        case TIMERANGE.weekly:
          timeRangeTipRevenue = totalTipRevenue * MATH.weekly;
          break;
        case TIMERANGE.monthly:
          timeRangeTipRevenue = (totalTipRevenue * MATH.weeklyAnnual) / MATH.monthlyAnnual;
          break;
        case TIMERANGE.quarterly:
          timeRangeTipRevenue = (totalTipRevenue * MATH.weeklyAnnual) / MATH.quarterlyAnnual;
          break;
        case TIMERANGE.annual:
          timeRangeTipRevenue = totalTipRevenue * MATH.weeklyAnnual;
          break;
        default:
          timeRangeTipRevenue = 0;
      }
    } else if (selectedTimeRange === TIMERANGE.monthly) {
      switch (selectedTimeBreakdown) {
        case TIMERANGE.weekly:
          timeRangeTipRevenue = (totalTipRevenue * MATH.monthlyAnnual) / MATH.weeklyAnnual;
          break;
        case TIMERANGE.monthly:
          timeRangeTipRevenue = totalTipRevenue * MATH.monthly;
          break;
        case TIMERANGE.quarterly:
          timeRangeTipRevenue = (totalTipRevenue * MATH.monthlyAnnual) / MATH.quarterlyAnnual;
          break;
        case TIMERANGE.annual:
          timeRangeTipRevenue = totalTipRevenue * MATH.monthlyAnnual;
          break;
        default:
          timeRangeTipRevenue = 0;
      }
    }

    return timeRangeTipRevenue;
  };

  /////////////////////////////////////////////
  //
  // TotalAnnualRevenue
  // >> totalTipRevenue * MATHY selectedtimerange annual
  //
  /////////////////////////////////////////////
  const totalAnnualRevenue = () => {
    const totalTipRevenue = tipRevenue();

    let timeRangeTipRevenue = 0;
    switch (selectedTimeRange) {
      case TIMERANGE.weekly:
        timeRangeTipRevenue = totalTipRevenue * MATH.weeklyAnnual;
        break;
      case TIMERANGE.monthly:
        timeRangeTipRevenue = totalTipRevenue * MATH.monthlyAnnual;
        break;
      default:
        timeRangeTipRevenue = 0;
    }

    return timeRangeTipRevenue;
  };

  /////////////////////////////////////////////
  //
  // TotalAnnualConsumerRevenue
  // >> totalTipRevenue * MATHY selectedtimerange annual
  //
  /////////////////////////////////////////////
  const totalAnnualConsumerRevenue = () => {
    const totalTipRevenue = tipRevenue();

    let timeRangeTipRevenue = 0;
    switch (selectedTimeRange) {
      case TIMERANGE.weekly:
        timeRangeTipRevenue = totalTipRevenue * MATH.weeklyAnnual;
        break;
      case TIMERANGE.monthly:
        timeRangeTipRevenue = totalTipRevenue * MATH.monthlyAnnual;
        break;
      default:
        timeRangeTipRevenue = 0;
    }

    return timeRangeTipRevenue;
  };

  /////////////////////////////////////////////
  //
  // Time Range Treatment Costs
  // >> Time Range value * total cost
  //
  /////////////////////////////////////////////
  const timeRangeTreatmentCosts = () => {
    const totalTipRevenue = treatmentCosts();
    //console.log('this is my total tip revenue ---<', totalTipRevenue);

    //This is a monthly payment

    let timeRangeTipRevenue = 0;
    if (selectedTimeRange === TIMERANGE.weekly) {
      switch (selectedTimeBreakdown) {
        case TIMERANGE.weekly:
          timeRangeTipRevenue = totalTipRevenue * MATH.weekly;
          break;
        case TIMERANGE.monthly:
          timeRangeTipRevenue = (totalTipRevenue * MATH.weeklyAnnual) / MATH.monthlyAnnual;
          break;
        case TIMERANGE.quarterly:
          timeRangeTipRevenue = (totalTipRevenue * MATH.weeklyAnnual) / MATH.quarterlyAnnual;
          break;
        case TIMERANGE.annual:
          timeRangeTipRevenue = totalTipRevenue * MATH.weeklyAnnual;
          break;
        default:
          timeRangeTipRevenue = 0;
      }
    } else if (selectedTimeRange === TIMERANGE.monthly) {
      switch (selectedTimeBreakdown) {
        case TIMERANGE.weekly:
          timeRangeTipRevenue = (totalTipRevenue * MATH.monthlyAnnual) / MATH.weeklyAnnual;
          break;
        case TIMERANGE.monthly:
          timeRangeTipRevenue = totalTipRevenue * MATH.monthly;
          break;
        case TIMERANGE.quarterly:
          timeRangeTipRevenue = (totalTipRevenue * MATH.monthlyAnnual) / MATH.quarterlyAnnual;
          break;
        case TIMERANGE.annual:
          timeRangeTipRevenue = totalTipRevenue * MATH.monthlyAnnual;
          break;
        default:
          timeRangeTipRevenue = 0;
      }
    }
    return timeRangeTipRevenue;
  };

  /////////////////////////////////////////////
  //
  // TotalAnnualTreatment Cost
  // >> totalTipRevenue * MATHY selectedtimerange annual
  //
  /////////////////////////////////////////////
  const totalAnnualTreatmentCosts = () => {
    const totalTipRevenue = treatmentCosts();

    let timeRangeTipRevenue = 0;
    switch (selectedTimeRange) {
      case TIMERANGE.weekly:
        timeRangeTipRevenue = totalTipRevenue * MATH.weeklyAnnual;
        break;
      case TIMERANGE.monthly:
        timeRangeTipRevenue = totalTipRevenue * MATH.monthlyAnnual;
        break;
      case TIMERANGE.quarterly:
        timeRangeTipRevenue = totalTipRevenue * MATH.quarterlyAnnual;
        break;
      case TIMERANGE.annual:
        timeRangeTipRevenue = totalTipRevenue * MATH.annualy;
        break;
      default:
        timeRangeTipRevenue = 0;
    }

    return timeRangeTipRevenue;
  };

  /////////////////////////////////////////////
  //
  // TimeRangeProfit
  // >> Time Range value * total cost
  //
  /////////////////////////////////////////////
  const timeRangeProfit = () => {
    const totalTipCost = treatmentCosts();
    const totalTipRevenue = tipRevenue();

    let timeRangeTipRevenue = 0;

    if (selectedTimeRange === TIMERANGE.weekly) {
      switch (selectedTimeBreakdown) {
        case TIMERANGE.weekly:
          timeRangeTipRevenue = (totalTipRevenue - totalTipCost) * MATH.weekly;
          break;
        case TIMERANGE.monthly:
          timeRangeTipRevenue = ((totalTipRevenue - totalTipCost) * MATH.weeklyAnnual) / MATH.monthlyAnnual;
          break;
        case TIMERANGE.quarterly:
          timeRangeTipRevenue = ((totalTipRevenue - totalTipCost) * MATH.weeklyAnnual) / MATH.quarterlyAnnual;
          break;
        case TIMERANGE.annual:
          timeRangeTipRevenue = (totalTipRevenue - totalTipCost) * MATH.weeklyAnnual;
          break;
        default:
          timeRangeTipRevenue = 0;
      }
    } else if (selectedTimeRange === TIMERANGE.monthly) {
      switch (selectedTimeBreakdown) {
        case TIMERANGE.weekly:
          timeRangeTipRevenue = ((totalTipRevenue - totalTipCost) * MATH.monthlyAnnual) / MATH.weeklyAnnual;
          break;
        case TIMERANGE.monthly:
          timeRangeTipRevenue = (totalTipRevenue - totalTipCost) * MATH.monthly;
          break;
        case TIMERANGE.quarterly:
          timeRangeTipRevenue = ((totalTipRevenue - totalTipCost) * MATH.monthlyAnnual) / MATH.quarterlyAnnual;
          break;
        case TIMERANGE.annual:
          timeRangeTipRevenue = (totalTipRevenue - totalTipCost) * MATH.monthlyAnnual;
          break;
        default:
          timeRangeTipRevenue = 0;
      }
    }

    return timeRangeTipRevenue;
  };

  /////////////////////////////////////////////
  //
  // TotalAnnualProfit
  // >> revenue - treatmentcosts
  //
  /////////////////////////////////////////////
  const totalAnnualProfit = () => {
    const totalTipCost = treatmentCosts();
    const totalTipRevenue = tipRevenue();
    let totalAnnualProfit = 0;
    switch (selectedTimeRange) {
      case TIMERANGE.weekly:
        totalAnnualProfit = (totalTipRevenue - totalTipCost) * MATH.weeklyAnnual;
        break;
      case TIMERANGE.monthly:
        totalAnnualProfit = (totalTipRevenue - totalTipCost) * MATH.monthlyAnnual;
        break;

      default:
        totalAnnualProfit = 0;
    }

    return totalAnnualProfit;
  };

  /////////////////////////////////////////////
  //
  // Tax Savings 179 Saving
  // >> Tax Rate *  System Cost
  //
  /////////////////////////////////////////////
  const taxSaving = () => {
    const machineCost = systemCosts;

    const taxBraket = convertToNumber(clinicTaxBraket) / 100; //Comes in as a whole number / 100 to make a decimal

    return machineCost * taxBraket;
  };

  /////////////////////////////////////////////
  //
  // Tax Savings 179 Deduction
  // >>  System Cost - taxSaving
  //
  /////////////////////////////////////////////
  const taxDeduction = () => {
    const machineCost = systemCosts;
    const taxSave = taxSaving(); //Comes in as a whole number / 100 to make a decimal

    return machineCost - taxSave;
  };

  /////////////////////////////////////////////
  //
  // Tips to break even
  // >>  Tax Duction / Net Profit
  //
  /////////////////////////////////////////////
  const tipsToBreakEven = () => {
    const machineCostAfterTaxDuduction = taxDeduction();
    const annualProfit = totalAnnualProfit();

    //We will next need to figure out how many tips we need a year
    const accounttipCount = roiTipCount;

    let annualTipCount = 0;
    if (selectedTimeRange === TIMERANGE.weekly) {
      //console.log('we need to times our total tip count by 48 weeks --->', accounttipCount, MATH.weeklyAnnual, accounttipCount * MATH.weeklyAnnual);
      annualTipCount = accounttipCount * MATH.weeklyAnnual;
    } else {
      //console.log('we need to times our total tip count by 12 months --->', accounttipCount, MATH.monthlyAnnual, accounttipCount * MATH.monthlyAnnual);
      annualTipCount = accounttipCount * MATH.monthlyAnnual;
    }

    const averageTipProfit = annualProfit / annualTipCount; //1 treatment average profit

    return machineCostAfterTaxDuduction / averageTipProfit;
  };

  /////////////////////////////////////////////
  //
  // Remove ROI data
  // >>  This is mainly used to navigate back to the home page
  //
  /////////////////////////////////////////////
  const clearROIData = () => {
    //console.log('I am now clearing the data');
    setROI({});
    setROIID(null);
    //setROILoading(true);
  };

  /////////////////////////////////////////////
  //
  // DELETE ROI
  // >>  This will remove the roi from the database
  //
  /////////////////////////////////////////////
  const deleteROI = async (id) => {
    await deleteDoc(doc(firestore, 'roi', id));
  };

  /////////////////////////////////////////////
  //
  // RESET ROI
  // >>  This will remove the roi from the database
  //
  /////////////////////////////////////////////
  const resetROI = async (id) => {
    //setROILoading(true);
    //setROIError('');
    setDrawerOpen(true);

    //setROI({});
    //setROIID(null);
    setClinicTaxBraket(0);
    setAddFinancing(false);
    setMonthlyPayment(0);
    setinterestRate(0);
    setSelectedTier('');
    setSelectedTips(defaultTips);
    setGrowthTips(defaultGrowthTips);
    setSelectedTimeRange(TIMERANGE.weekly);
    setSelectedBreakdown(TIMERANGE.weekly);
    setNextTier({});
    setSystemCosts(0);
    setTableBreakdown([]);
    setROITipCount(0);
    setChartData([]);
    setChartXData([]);
    setCurrentTierBreakEven({});
    setNextTierBreakEven({});
    setROIMode(MODE.capital);

    //Consumer Variables
    setCurrentSpend(0);
    setConsumerChartData([]);
    setConsumerChartXData([]);
    setROIGrowthTipCount(0);
    setConsumerDifference({});
    setconsumerPracticeGrowthRevenue(0);
    setconsumerTreatmentGrowthCost(0);
    setconsumerTreatmentGrowthProfit(0);
    setconsumerAnnualPracticeGrowthRevenue(0);
    setconsumerAnnualTreatmentGrowthCost(0);
    setconsumerAnnualTreatmenGrowthtProfit(0);
  };

  ///////////////////////////////////////////////////////////////
  //
  // GENERATE ROI PDF DATASET
  // >>  This will take a roi and generate a pdf of its data
  //
  //////////////////////////////////////////////////////////////
  const generateROIpdfDataSet = async (roi) => {
    /* console.log("roi ->",roi);
    console.log("user ->",user);
    console.log("chartData ->",chartData);
    console.log("chartXData ->",chartXData);
    console.log("roiTipCount ->",roiTipCount);
    console.log("currentTierBreakEven ->",currentTierBreakEven);
    console.log("newxtTierBreakEven ->",newxtTierBreakEven); */
    if (roi && user && chartData && chartXData && roiTipCount && currentTierBreakEven && newxtTierBreakEven) {
      const MoneyFormater = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      const MakeMoneyValue = ({ value }) => {
        return MoneyFormater.format(value);
      };

      const tierName = (id) => {
        //we have id, we need name
        switch (id) {
          case '"EnHRIMZGhhJZeTCUL6NX"':
            return 'Bronze';
          case '3IaLvKVToLsbcC8neru7':
            return 'Silver';
          case 'hfgJtLRuei3Ac42Gu1tj':
            return 'Gold';
          case 'TktE8KkUfRptydPNLOk1':
            return 'Platinum';
          case 'eEH4ihpXS9hqi1Bd3Q93':
            return 'Emerald';
          case '3vYBprZ763vNRowuvVRI':
            return 'Ruby';
          case '7FiOjjal0x2LkVmv9gbv':
            return 'Diamond';
          default:
            return 'Bronze';
        }
      };

      //profitblock  - treatment rows

      

      const ProfitBlock = (row, i, tier) => {
        //console.log('this is my row --->', row)
        //console.log('this is my  values sperately ---->',  row[tier], (row[tier] / row.tipsPerPack), row.treatmentPrice, row.other , row.amount )
      //console.log('this is my week profit for PDF ------> ', ( row.treatmentPrice - (row[tier] / row.tipsPerPack)   + (row.other ? row.other : 0)) * row.amount )
        
        return {
          treatmentPrice: MakeMoneyValue({ value: row.treatmentPrice }),
          consumePrice: MakeMoneyValue({ value: row[tier] / row.tipsPerPack }),
          other: row.other ? MakeMoneyValue({ value: row.other }) : 0,
          weekProfit: MakeMoneyValue({ value: ((row.treatmentPrice - (row[tier] / row.tipsPerPack)    + (row.other ? row.other : 0)) * row.amount  )}),
        };
      };
      let profitBlockRows = [];
      Object.keys(selectedTips).forEach((p) => {
        if (selectedTips[p].id !== '') {
          profitBlockRows.push(ProfitBlock(selectedTips[p], p, selectedTier));
        }
      });
      const combinedData = selectedTips
        .map((tip, index) => {
          // Check if tip.id is not empty
          if (tip.id !== '') {
            // Iterate through each key in 'tip'
            for (var key in tip) {
              // Check if the value is NaN and it's a numeric value
              if (isNaN(tip[key]) && typeof tip[key] === 'number') {
                tip[key] = 0; // Set it to 0 if it's NaN
              }
            }
            return {
              tip: tip,
              profitBlockRow: profitBlockRows[index] ? profitBlockRows[index] : '',
            };
          } else {
            // If tip.id is empty, return null so that later V
            return null;
          }
        })
        .filter((item) => item !== null); // Filter out null values if any

      //format roi system prices
      function updatePricesAndCopy(obj) {
        const newObj = Array.isArray(obj) ? [] : {};

        for (const key in obj) {
          if (typeof obj[key] === 'object') {
            newObj[key] = updatePricesAndCopy(obj[key]); // Recursively traverse the object
          } else if (key === 'price') {
            newObj[key] = MakeMoneyValue({ value: obj[key] }); // Update the price
          } else {
            newObj[key] = obj[key]; // Preserve other data
          }
        }

        return newObj;
      }
      const formattedRoi = updatePricesAndCopy(roi);

      //console.log('this is my time range singular ----?>', TIMERANGESINGULAR[selectedTimeRange]);

      let superData = {
        roi: formattedRoi,
        user: user,
        tierName: tierName(selectedTier),
        selectedTips: combinedData,
        selectedTier: selectedTier,
        totalAnnualRevenue: convertToCurrency(totalAnnualRevenue()),
        totalAnnualTreatmentCosts: convertToCurrency(totalAnnualTreatmentCosts()),
        totalAnnualProfit: convertToCurrency(totalAnnualProfit()),
        clinicTaxBraket: clinicTaxBraket,
        tipsToBreakEven: convertToRoundedNumber(tipsToBreakEven()),
        timeRangePracticeRevenue: convertToCurrency(timeRangePracticeRevenue()),
        timeRangeTreatmentCosts: convertToCurrency(timeRangeTreatmentCosts()),
        timeRangeProfit: convertToCurrency(timeRangeProfit()),
        taxSaving: convertToCurrency(taxSaving()),
        taxDeduction: convertToCurrency(taxDeduction()),
        tableBreakdown: tableBreakdown,
        roiTipCount: roiTipCount,
        currentTierBreakEven: currentTierBreakEven,
        currentTierBreakEvenNumber: currentTierBreakEven.breakEven ? currentTierBreakEven.breakEven.toFixed(2) : 0,
        newxtTierBreakEven: newxtTierBreakEven,
        newxtTierBreakEvenNumber: newxtTierBreakEven.breakEven ? newxtTierBreakEven.breakEven.toFixed(2) : 0,
        selectedTimeRange: selectedTimeRange,
        selectedTimeBreakdown: selectedTimeBreakdown,
        singularTimeRange: TIMERANGESINGULAR[selectedTimeRange],
        singularTimeBreakdown: TIMERANGESINGULAR[selectedTimeBreakdown],
      };
      return superData;
    } else return null;
  };

  ///////////////////////////////////////////////////////////////
  //
  // GENERATE ROI PDF
  // >>  This will take a roi and generate a pdf of its data
  //
  //////////////////////////////////////////////////////////////
  const generateROIpdf = async (roi) => {
    setPDFLoading(true);
    const functions = getFunctions();
    const callGeneratePDF = httpsCallable(functions, 'pdf-generatePDF');

    if (roi && user && chartData && chartXData && roiTipCount && currentTierBreakEven && newxtTierBreakEven) {
      if (roiMode === MODE.capital) {
        let pdfData = await generateROIpdfDataSet(roi);
        //console.log("pdfData after call to generate ->", pdfData);
        //store this so that roi pdf's can be generated with out navigating to the display
        const documentRef = doc(firestore, 'roi', roi.id);
        // Update the document
        await updateDoc(documentRef, {
          pdfData: pdfData,
          pdfCreated: new Date(),
        }).catch((error) => {
          console.error('Error updating document: ', error);
        });
        let pdfResponse = await callGeneratePDF({ pdfData });

        if (pdfResponse.data) {
          if (pdfResponse.data.pdfFile) {
            let pdfFile = pdfResponse.data.pdfFile[0];
            let pdfFileName = pdfResponse.data.fileName;
            const link = document.createElement('a');
            link.download = pdfFile;
            link.href = pdfFile;
            link.target = '_blank';
            link.click();
          }
        }
      } else {
        //console.log('########## we are creating a CONSUMER pdf ##########');
        //console.log('this is my chart data i am sending ---> ', consumerChartData, consumerChartXData);
        const blob = await pdf(
          <ConsumerROIpdf
            data={roi}
            user={user}
            tips={selectedTips}
            selectedTimeRange={selectedTimeRange}
            growthTips={growthTips}
            tier={selectedTier}
            chartData={chartData}
            chartXData={chartXData}
            consumerChartData={consumerChartData}
            consumerChartXData={consumerChartXData}
            totalAnnualRevenue={totalAnnualRevenue}
            totalAnnualTreatmentCosts={totalAnnualTreatmentCosts}
            totalAnnualProfit={totalAnnualProfit}
            timeRangeTreatmentCosts={timeRangeTreatmentCosts}
            timeRangeProfit={timeRangeProfit}
            timeRangePracticeRevenue={timeRangePracticeRevenue}
            consumerAnnualPracticeGrowthRevenue={consumerAnnualPracticeGrowthRevenue}
            consumerAnnualTreatmentGrowthCost={consumerAnnualTreatmentGrowthCost}
            consumerAnnualTreatmenGrowthtProfit={consumerAnnualTreatmenGrowthtProfit}
            roiTipCount={roiTipCount}
            roiGrowthTipCount={roiGrowthTipCount}
            currentTierBreakEven={currentTierBreakEven}
            newxtTierBreakEven={newxtTierBreakEven}
            selectedTimeBreakdown={selectedTimeBreakdown}
            consumerDifference={consumerDifference}
            consumerPracticeGrowthRevenue={consumerPracticeGrowthRevenue}
            consumerTreatmentGrowthCost={consumerTreatmentGrowthCost}
            consumerTreatmentGrowthProfit={consumerTreatmentGrowthProfit}
          />
        ).toBlob();
        //console.log('blob -> ', blob);
        saveAs(blob, safeFileName);
      }
    }
    setPDFLoading(false);
  };
  /////////////////////////////////////////////
  // OLD way, duplicated no Capital to above
  /////////////////////////////////////////////
  const generateROIpdfx = async (roi) => {
    //console.log('roi in generate pdf -> ', roi);
    //gather all the data
    // going to need to make calls to cald required data
    /* setPDFROI(roi);
    const loadROI = await getDoc(doc(firestore, 'roi', roi.id));
    setROIFromFirestore({ id: roi.id, data: loadROI }); */
    //setROIID(roi.id);

    /* RACE CONDITION */
    /* ALL THE USE EFFECTS THAT FIRE WHEN SET ROI FROM FIRESTORE IS CALLED FINISH AFTER PDF IS GENERATED */

    let fileName = roi.clinicName.split(' ').join('-') + '-';
    let safeFileName = fileName + 'ROI';

    //console.log('is pdfCapitalDataReady', pdfCapitalDataReady);
    //console.log('is pdfConsumerDataReady', pdfConsumerDataReady);
    if (roi && user && chartData && chartXData && roiTipCount && currentTierBreakEven && newxtTierBreakEven) {
      if (roiMode === MODE.capital) {
        //console.log('!!!!!!!! we are creating an capital pdf !!!!!!!!');
        const blob = await pdf(
          <ROIReport
            data={roi}
            user={user}
            chartData={chartData}
            chartXData={chartXData}
            selectedTips={selectedTips}
            selectedTier={selectedTier}
            totalAnnualRevenue={totalAnnualRevenue}
            totalAnnualTreatmentCosts={totalAnnualTreatmentCosts}
            totalAnnualProfit={totalAnnualProfit}
            taxSaving={taxSaving}
            taxDeduction={taxDeduction}
            tipsToBreakEven={tipsToBreakEven}
            timeRangePracticeRevenue={timeRangePracticeRevenue}
            timeRangeTreatmentCosts={timeRangeTreatmentCosts}
            timeRangeProfit={timeRangeProfit}
            tableBreakdown={tableBreakdown}
            roiTipCount={roiTipCount}
            currentTierBreakEven={currentTierBreakEven}
            newxtTierBreakEven={newxtTierBreakEven}
            selectedTimeRange={selectedTimeRange}
            selectedTimeBreakdown={selectedTimeBreakdown}
          />
        ).toBlob();
        //console.log('blob -> ', blob);
        saveAs(blob, safeFileName);
        //after save set to false
        setPDfCapitalDataReady(false);
        setPDfConsumerDataReady(false);
      } else {
        //console.log('########## we are creating a CONSUMER pdf ##########');
        //console.log('this is my chart data i am sending ---> ', consumerChartData, consumerChartXData);

        const blob = await pdf(
          <ConsumerROIpdf
            data={roi}
            user={user}
            tips={selectedTips}
            selectedTimeRange={selectedTimeRange}
            growthTips={growthTips}
            tier={selectedTier}
            chartData={chartData}
            chartXData={chartXData}
            consumerChartData={consumerChartData}
            consumerChartXData={consumerChartXData}
            totalAnnualRevenue={totalAnnualRevenue}
            totalAnnualTreatmentCosts={totalAnnualTreatmentCosts}
            totalAnnualProfit={totalAnnualProfit}
            timeRangeTreatmentCosts={timeRangeTreatmentCosts}
            timeRangeProfit={timeRangeProfit}
            timeRangePracticeRevenue={timeRangePracticeRevenue}
            consumerAnnualPracticeGrowthRevenue={consumerAnnualPracticeGrowthRevenue}
            consumerAnnualTreatmentGrowthCost={consumerAnnualTreatmentGrowthCost}
            consumerAnnualTreatmenGrowthtProfit={consumerAnnualTreatmenGrowthtProfit}
            roiTipCount={roiTipCount}
            roiGrowthTipCount={roiGrowthTipCount}
            currentTierBreakEven={currentTierBreakEven}
            newxtTierBreakEven={newxtTierBreakEven}
            selectedTimeBreakdown={selectedTimeBreakdown}
            consumerDifference={consumerDifference}
            consumerPracticeGrowthRevenue={consumerPracticeGrowthRevenue}
            consumerTreatmentGrowthCost={consumerTreatmentGrowthCost}
            consumerTreatmentGrowthProfit={consumerTreatmentGrowthProfit}
          />
        ).toBlob();
        //console.log('blob -> ', blob);
        saveAs(blob, safeFileName);
      }
    }
  };

  ///////////////////////////////////////////////////////////////
  //
  // Make Folder
  // >>  This will add a folderName to users folder array
  //
  //////////////////////////////////////////////////////////////
  const makeFolder = async (folderName) => {
    try {
      const userDocRef = doc(firestore, 'users', user.id);

      // Check if the user document exists
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        // The document exists, get its data
        const userData = userDocSnapshot.data();

        // Check if 'folder' datapoint exists and is an array
        if (Array.isArray(userData.folders)) {
          // If 'folder' exists and is an array, update the array by adding 'folderName'
          const updatedFolders = [...userData.folders, folderName];
          await updateDoc(userDocRef, {
            folders: updatedFolders,
          });
          setRefreshUser(true);
        } else if (userData.folders === undefined) {
          // If 'folder' does not exist, create it with 'folderName' as the first string
          await updateDoc(userDocRef, {
            folders: [folderName],
          });
          setRefreshUser(true);
        } else {
          // 'folder' exists but is not an array, handle the error or log a message
          console.log('Error: "folder" exists but is not an array.');
        }

        return true;
      } else {
        console.log('User document not found.');
        return false;
      }
    } catch (err) {
      console.log('Error:', err);
      return err;
    }
  };

  ///////////////////////////////////////////////////////////////
  //
  // Add ROI to Folder
  // >>  This will add a folder to ROI
  //
  //////////////////////////////////////////////////////////////
  const addROItoFolder = async (roi, folderName) => {
    //console.log('addROItoFolder roi-> ',roi);
    //console.log('addROItoFolder folderName-> ',folderName);
    try {
      await setDoc(
        doc(firestore, 'roi', roi.id),
        {
          folder: folderName,
        },
        { merge: true }
      );
    } catch (err) {
      console.log('this is my error ---->', err);
      return err;
    }
  };

  /////////////////////////////////////////////////////////////////////////////
  //
  // Delete Folder
  // >>  This will delete a folderName and move ROI in it to un-foldered
  //
  ////////////////////////////////////////////////////////////////////////////
  const deleteFolder = async (folderName) => {
    //delete folder
    try {
      const userDocRef = doc(firestore, 'users', user.id);

      // Check if the user document exists
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        // The document exists, get its data
        const userData = userDocSnapshot.data();

        // Check if 'folder' datapoint exists and is an array
        if (Array.isArray(userData.folders)) {
          // If 'folder' exists and is an array, remove 'folderName' if it exists
          const updatedFolders = userData.folders.filter((f) => f !== folderName);
          await updateDoc(userDocRef, {
            folders: updatedFolders,
          });
          setRefreshUser(true);
        } else {
          // 'folder' is not an array, log a message
          console.log('Error: "folders" is not an array.');
        }
        //move any roi from folder
        //map repsROI
        //if roi.folder === folderName
        //update specificROI
        repROIs.forEach((roi) => {
          if (roi.folder === folderName) {
            removeROIFromFolder(roi);
          }
        });
        return true;
      } else {
        console.log('User document not found.');
        return false;
      }
    } catch (err) {
      console.log('Error:', err);
      return err;
    }
  };

  ///////////////////////////////////////////////////////////////
  //
  // Remove ROI from Folder
  // >>  This remove a ROI from a folder
  //
  //////////////////////////////////////////////////////////////
  const removeROIFromFolder = async (roi) => {
    try {
      await setDoc(
        doc(firestore, 'roi', roi.id),
        {
          folder: '',
        },
        { merge: true }
      );
    } catch (err) {
      console.log('this is my error ---->', err);
      return err;
    }
  };

  const contextValue = {
    roi,
    PDFroi,
    repROIs,
    repROIsLoading,
    roiID,
    roiLoading,
    roiAlert,
    roiError,
    roiSaving,
    clinicTaxBraket,
    addFinancing,
    setAddFinancing,
    createNewROI,
    loadROI,
    addSystem,
    changeSystemPrice,
    changeClinicName,
    changeReportsName,
    setClinicTaxBraket,
    monthlyPayment,
    setMonthlyPayment,
    interstRate,
    setinterestRate,
    selectedTier,
    setSelectedTier,
    selectedTips,
    setSelectedTips,
    consumablePrice,
    addAnotherTreatment,
    removeTreatment,
    selectedTimeRange,
    setSelectedTimeRange,
    timeRangePracticeRevenue,
    totalAnnualRevenue,
    timeRangeTreatmentCosts,
    totalAnnualTreatmentCosts,
    totalAnnualProfit,
    timeRangeProfit,
    nextTier,
    selectedTimeBreakdown,
    setSelectedBreakdown,
    taxSaving,
    taxDeduction,
    tipsToBreakEven,
    forceROISave,
    setForceROISave,
    drawerOpen,
    setDrawerOpen,
    tipDrawerOpen,
    setTipDrawerOpen,
    addTipCount,
    removeTipCount,
    roiTipCount,
    tableBreakdown,
    chartData,
    chartXData,
    currentTierBreakEven,
    newxtTierBreakEven,
    clearROIData,
    deleteROI,
    roiMode,
    setROIMode,
    currentSpend,
    setCurrentSpend,
    growthTips,
    setGrowthTips,
    addAnotherTreatmentGrowth,
    removeTreatmentGrowth,
    consumablePriceGrowth,
    addTipCountGrowth,
    removeTipCountGrowth,
    consumerChartData,
    consumerChartXData,
    roiGrowthTipCount,
    consumerDifference,
    consumerPracticeGrowthRevenue,
    consumerTreatmentGrowthCost,
    consumerTreatmentGrowthProfit,
    consumerAnnualPracticeGrowthRevenue,
    consumerAnnualTreatmentGrowthCost,
    consumerAnnualTreatmenGrowthtProfit,
    consumablePriceSingle,
    resetROI,
    generateROIpdf,
    generateROIpdfDataSet,
    roiMode,
    makeFolder,
    addROItoFolder,
    deleteFolder,
    removeROIFromFolder,
    consumerTreatmentCost,
    consumerTreatmentProfit,
    consumerAnnualPracticeRevenue,
    consumerAnnualTreatmentCost,
    consumerAnnualTreatmentProfit,
    consumerPracticeRevenue,
    pdfLoading,
  };

  return <ROIContext.Provider value={contextValue}>{children}</ROIContext.Provider>;
};

export const useROIContext = () => useContext(ROIContext);
